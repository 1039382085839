import React from "react";
import { Box, Typography } from "@mui/material";
import MySkeleton from "../../../../../components/Skeleton";
import ChatAvatarComponent from "../../chat/ChatAvatarComponent";
import { useRedirectUserCommunityProfile } from "../../../components/common";
import LinearProgressWithLabel from "../../Matches/components/LinearProgressWithLabel";

interface LeaderboardData {
    id?: string;
    mid: string;
    headshot: string;
    loader?: boolean;
    displayName: string;
    goalProgress: number;
    avgProgress?: number;
}

const LeaderBoardCard: React.FC<LeaderboardData> = ({ mid, displayName, headshot, goalProgress, loader }) => {
    const moveToUserCommunityProfile = useRedirectUserCommunityProfile();
    return (
        <Box
            gap="8px"
            width="100%"
            display="flex"
            alignItems="center"
            className={'cursor-pointer'}
            onClick={() => moveToUserCommunityProfile(mid)}
        >
            <ChatAvatarComponent
                height="48px"
                width="48px"
                type="noStatus"
                image={headshot}
                loading={loader}
                firstLetter={displayName?.slice(0, 1)}
            />
            <Box flex={1} minWidth={0}>
                <Typography variant="h6" noWrap>
                    {loader ? <MySkeleton height={14} width={'100%'} /> : displayName}
                </Typography>
                <Box width="100%">
                    {loader ? (
                        <MySkeleton width={'80%'} />
                    ) : (
                        <LinearProgressWithLabel value={goalProgress} />
                    )}
                </Box>
            </Box>
        </Box>
    );
};
export default LeaderBoardCard;
