import { Box } from '@mui/material';
import MySkeleton from '../../../../components/Skeleton';

/** Right side Loader */
export const TasksQuesLoader = () => {
  return (
    <Box className="CardBox" mb={2} p={2}>
      <MySkeleton variant="text" height={20} width={150} sx={{ paddingBottom: '10px' }} />
      <MySkeleton variant="text" height={20} />
      <MySkeleton variant="text" height={20} />

      <MySkeleton height="1px" sx={{ margin: '20px 0' }} />

      <MySkeleton variant="text" height={20} sx={{ marginBottom: '20px' }} />
      <MySkeleton variant="rounded" sx={{ marginBottom: '20px' }} height={'50px'} />

      <MySkeleton height="2px" sx={{ margin: '20px 0' }} />

      <MySkeleton variant="text" height={20} width={250} sx={{ marginBottom: '10px' }} />
      <MySkeleton variant="rounded" sx={{ marginBottom: '10px' }} width={'50%'} height={'50px'} />
      <MySkeleton variant="rounded" sx={{ marginBottom: '10px' }} width={'50%'} height={'50px'} />
    </Box>
  );
};

export const MentorLoader = () => (
  <Box className="CardBox" mb={2}>
    <Box my={1} px={2}>
      <MySkeleton variant="text" height={20} width={150} />
    </Box>
    <MySkeleton height="2px" />
    <Box className="resume-main-sec" justifyContent={'space-between'}>
      {[...Array(3).keys()].map((each, idx) => {
        return (
          <Box display={'flex'} flexDirection={'column'} gap={2} key={idx}>
            <Box display={'flex'} gap={1}>
              <MySkeleton variant="circular" height={20} width={20} />
              <MySkeleton variant="text" height={20} width={100} />
            </Box>
            <Box display={'flex'} gap={1}>
              <MySkeleton variant="rounded" height={60} width={60} />
              <Box>
                <MySkeleton variant="text" height={20} width={200} />
                <MySkeleton variant="text" height={20} width={200} />
                <MySkeleton variant="rounded" height={20} width={100} sx={{ marginTop: '10px' }} />
                <Box display={'flex'} gap={1} mt={2}>
                  <MySkeleton variant="rounded" height={32} width={100} />
                  <MySkeleton variant="rounded" height={32} width={100} />
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  </Box>
);

export const ArticleLoader = () => {
  return (
    <>
      {/* Lesson Materials */}
      <Box className="CardBox" mb={2}>
        <Box my={1} px={2}>
          <MySkeleton variant="text" height={20} width={150} />
        </Box>
        <MySkeleton height="2px" />
        <Box className="resume-main-sec" gap={3}>
          {[...Array(3).keys()].map((each, idx) => {
            return (
              <Box display={'flex'} gap={2} key={idx}>
                <MySkeleton variant="rounded" height={75} width={118} />
                <Box>
                  <MySkeleton variant="text" height={24} width={150} />
                  <MySkeleton variant="text" height={24} width={150} />
                  <MySkeleton variant="text" height={20} width={40} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

/** Left side Loader */
export const TasksLoader = () => {
  return (
    <Box px={2} pb={2} height={'calc(100% - 30px)'} overflow={'auto'}>
      {[...Array(5).keys()].map((each, idx, array) => {
        const isLastItem = idx === array.length - 1;

        return (
          <Box key={idx}>
            <Box display="flex" gap={'8px'} alignItems={'center'} my={2}>
              <Box width={'100%'}>
                <MySkeleton width={100} />
                <MySkeleton width={200} />
              </Box>
              <MySkeleton variant="rounded" width={30} height={30} />
              <Box>
                <MySkeleton variant="rectangular" height={20} width={3} />
              </Box>
            </Box>
            <Box mb={{ xs: 1, sm: 2 }} width={'100%'}>
              <Box className="main-banner-sec" width={'100%'} mb={0.5}>
                <MySkeleton variant="circular" width={20} height={20} />
                <MySkeleton width={'100%'} />
              </Box>
              <MySkeleton width={70} />
            </Box>
            {isLastItem ? null : <MySkeleton width={'100%'} height={'2px'} />}
          </Box>
        );
      })}
    </Box>
  );
};
