import React, { useState } from "react";
import {
    Box,
    Link,
    Divider,
    Typography
} from "@mui/material";
import LeaderBoardCard from "./LeaderBoardCard";
import MySkeleton from "../../../../../components/Skeleton";
import { CardStyle } from "../../../style-components/CardStyling";
import CompletedLeaderboardUsers from "./CompletedLeaderboardUsers";
import { generateDummyLeaderboardData } from "../../../components/common";

const Leaderboard: React.FC<{ data: any[]; loader?: boolean }> = ({ data = [], loader }) => {
    const classes = CardStyle();
    const [openDialog, setOpenDialog] = useState(false);
    const getData = loader ? generateDummyLeaderboardData(9) : data; // For Loader
    const leaderboardData = [...getData].sort((a, b) => b.goalProgress - a.goalProgress).filter((item)=> item?.goalProgress < 100);
    const chunkedData = Array.from({ length: Math.ceil(leaderboardData.length / 3) }, (_, i) =>
        leaderboardData.slice(i * 3, i * 3 + 3)
    );

    /** View Completed popup open */
    const handleDialogOpen = (open: boolean) => { setOpenDialog(open) };

    return (
        <>
            <Box className={classes.cardBox}>
                {/* Header Section */}
                <Box display="flex" justifyContent="space-between" p={2}>
                    <Typography variant="h5">{loader ? <MySkeleton width={100} /> : "Leaderboard"}</Typography>
                    <Link
                        variant="body1"
                        color="info.main"
                        onClick={() => handleDialogOpen(true)} // Open dialog
                    >
                        {loader ? <MySkeleton width={100} /> : "View Completed"}
                    </Link>
                </Box>

                <Divider />

                {/* Leaderboard Cards */}
                {chunkedData.length > 0 ? (
                    <Box overflow="auto" px={2} pt={2} pb={1}>
                        <Box display="flex" gap={2} overflow="auto" flexWrap="nowrap">
                            {chunkedData.map((row, index) => (
                                <Box key={index} display="flex" flexDirection="column" gap={1.5} mb={1} width="250px" minWidth="250px">
                                    {row.map((item) => (
                                        <LeaderBoardCard
                                            key={item?.id}
                                            mid={item?.mid}
                                            loader={loader}
                                            headshot={item?.headshot}
                                            displayName={item?.displayName}
                                            avgProgress={0}
                                            goalProgress={item?.goalProgress || 0}
                                        />
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ) : (
                    <Typography textAlign="center" py={{ xs: 2, md: 5 }}>
                        No Data Found
                    </Typography>
                )}
            </Box>
            
            {/* Dialog for View Completed */}
            <CompletedLeaderboardUsers
                loader={loader}
                open={openDialog}
                data={getData?.filter((item)=>item.goalProgress === 100)}
                onClose={() => handleDialogOpen(false)}
            />
        </>
    );
};

export default Leaderboard;
