import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import '../assets/style/sass/htmlContent.scss';

interface IRenderHTML {
    htmlContent: string;
    className?: string;
    fallbackText?: string;
    sx?: SxProps<Theme>;
}

const RenderHTML: React.FC<IRenderHTML> = ({
    htmlContent,
    className = 'htmlContent',
    sx,
    fallbackText,
}) => {
    /** Check if the content is empty by removing all HTML tags and whitespace. */
    const isContentEmpty = (content: string) => {
        return !content?.replace(/<[^>]*>/g, '').trim();
    };

    /** Sanitize and clean the input HTML content. */
    const sanitizeContent = (content: string) => {
        return content
            .replace(/<p[^>]*>\s*<\/p>/g, '<br/>') // Replace empty <p> tags or those with only whitespace
            .replace(/<p[^>]*>\s*(<span[^>]*>\s*<\/span>\s*)*<\/p>/g, '<br/>')// Replace <p> tags containing only empty <span> elements
            .replace(/<br>\s*<\/p>/g, '<br/><br/></p>')// Ensure <br> tags before closing </p> are handled correctly
            .trim();
    };

    const sanitizedContent = isContentEmpty(htmlContent)
        ? `<span class='fallbackText'>${fallbackText}</span>`
        : sanitizeContent(htmlContent);

    return (
        <Box
            width="100%"
            className={className}
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            sx={sx}
        />
    );
};

export default RenderHTML;
