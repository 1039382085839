/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import _ from 'lodash';
import { API } from '../../../../api';
import { Box, Link, Stack, Button, Tooltip, Divider, IconButton, Typography } from '@mui/material';
import MySkeleton from '../../../../components/Skeleton';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../redux/hooks';
// import Upvotes from '../../components/Upvotes';
import { navigateToChat } from '../NavigateToChat';
import CallBookingModal from '../CallBookingModal';
import CancelIcon from '@mui/icons-material/Cancel';
import RoleTypeTag from '../../components/RoleTypeTag';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getDisplayName, GroupBasedRoleFormatter } from '../../components/common';
import { ChatIcon, VideoCallIcon } from '../../../../utils/Icons';
// import ChatWithMeDialog from '../../tabs/chat/ChatWithMeDialog';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import { RequestMatch, RequestMatchReject } from '../Matches/components/RequestAndRejectMatch';
import { checkOrfium } from '../../../../utils/orfiumFunctions';

interface MyMentorCardProps {
  myMentorList: any;
  loading?: boolean;
  myRequestedMentorList?: any;
  doReload?: () => void;
}

const MyMentorCard: React.FC<MyMentorCardProps> = ({
  myMentorList,
  loading,
  myRequestedMentorList,
  doReload
}: MyMentorCardProps) => {
  const bookedCallDetails: any = '';
  const [openCalendarModalVal, setCalendarOpen] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [participantUser, setParticipantUser] = useState([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const personalThreadsArr = useAppSelector((state) => state.acsChannels.personalThreadsArr);
  const communityMembers = useAppSelector((state) => state.acsCommunityUsers.data);
  const commList = useAppSelector((state) => state.getCommunityMembers.data);
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const groupId = selectedGroupId.id || '';

  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  const mentorRoleText = GroupBasedRoleFormatter('mentor', 'Mentor'); // Mentor
  const mentorRoleTextLowerCase = mentorRoleText.toLowerCase(); // mentor
  const { state } = useLocation();

  /** Chat with me Popup open */
  // const [openChatWithMe, setOpenChatWithMe] = useState(false);
  // const handleClickChatWithMe = (flag: boolean) => setOpenChatWithMe(flag);

  /** For request and reject match dialog */
  const [openRequestMatch, setOpenRequestMatch] = useState(false);
  const [openRequestMatchReject, setOpenRequestMatchReject] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [mentorId, setMentorId] = useState('');
  const [menteeId, setMenteeId] = useState('');
  const [commId, setCommId] = useState('');
  const [actionId, setActionId] = useState('');
  const handleRequestMatch = (flag: boolean) => setOpenRequestMatch(flag);
  const handleRequestMatchReject = (flag: boolean) => setOpenRequestMatchReject(flag);
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);

  const rejectMatchRequest = (requestId: string, mentorId: string, menteeId: string, commId: string) => {
    setRequestId(requestId);
    setMentorId(mentorId);
    setMenteeId(menteeId);
    setCommId(commId);
    handleRequestMatchReject(true);
  };

  const acceptMatchRequest = async (requestId: string, mentorId: string, menteeId: string) => {
    const grpId = groupId;
    setActionId(requestId);
    try {
      const response = await API.acceptMatchRequest({ grpId, mentorId, menteeId, requestId });
      if (response.status === 200) {
        // setActionId('');
        if (doReload) {
          doReload();
        }
      } else {
        setActionId('');
      }
    } catch (e) {
      setActionId('');
    }
  };

  const removeMatchRequest = async (requestId: string) => {
    const grpId = groupId;
    try {
      const response = await API.removeMatchRequest({ grpId, requestId });
      if (response.status === 200) {
        if (doReload) {
          doReload();
        }
      } else {
        //
      }
    } catch (e) {
      //
    }
  };

  const mentorCardCount = myMentorList?.length || 0;
  const requestedMentorCount = myRequestedMentorList?.length || 0;
  const totalMentorsFound = mentorCardCount > 0 || requestedMentorCount > 0;
  const totalMentorsCount = mentorCardCount + requestedMentorCount;
  let mentorsWithRequestFlag: any[];
  if (Array.isArray(myRequestedMentorList) && myRequestedMentorList.length > 0) {
    mentorsWithRequestFlag = myRequestedMentorList.map((mentor: any) => ({
      ...mentor,
      requestFlag: true
    }));
  } else {
    mentorsWithRequestFlag = [];
  }
  const totalMentors = [...mentorsWithRequestFlag, ...(myMentorList || [])];

  const moveToUsercommunityProfile = (userId: string) => {
    const mem = communityMembers.find((item: { userId: string }) => item.userId === userId);
    const modifiedMem = { ...mem, id: _.get(mem, 'id.coId', '') };
    const allCommunityMembersList = communityMembers;
    if (mem) {
      navigate('/app/communitymember', {
        state: { mem: modifiedMem, allCommunityMembersList: commList }
      });
    }
  };

  const handleNavigateToChat = (member: any) => {
    const listedInCommunity = communityMembers?.find((each) => each?.userId === _.get(member, 'userId', ''));
    let isInCommunity = false;
    if (listedInCommunity) {
      isInCommunity = true;
    }
    navigateToChat(member?.communicationId, isInCommunity, personalThreadsArr, navigate, dispatch);
  };

  /** Calendar modal */
  const handleCalendarOpen = (member: any) => {
    setDisplayName(member.displayName);
    setParticipantUser(member);
    setCalendarOpen(true);
  };

  const handleCalendarClose = () => {
    setDisplayName('');
    setParticipantUser([]);
    setCalendarOpen(false);
  };

  /** Mentor card */
  function MentorCard({ mentor }: any) {
    let matchRequested = false;
    if (mentor?.requestFlag) {
      matchRequested = true;
    }
    const matchRejected = mentor?.rejected ? true : false;
    const rejectMessage = mentor?.rejectMessage;
    const requestMessage = mentor?.requestMessage;

    return (
      <>
        <Box
          display="flex"
          flexDirection={forMobile ? 'row' : 'column'}
          // justifyContent="space-between"
          gap="8px"
          width={forMobile ? '100%' : '359px'}
          minWidth={forMobile ? '100%' : '359px'}
        >
          <Box
            flex={forMobile ? 1 : ''}
            display="flex"
            gap="8px"
            alignItems="center"
            width={forMobile && !matchRequested && !matchRejected ? 'calc(100% - 56px)' : '100%'}
          >
            <Box onClick={() => moveToUsercommunityProfile(mentor?.userId)}>
              <ChatAvatarComponent
                image={mentor?.headshot}
                type="noStatus"
                firstLetter={getDisplayName(mentor).charAt(0)}
                width="48px"
                height="48px"
                cursor="pointer"
                loading={loading}
              />
            </Box>
            <Box width="calc(100% - 56px)">
              {/* Mobile else Desktop */}
              <Box className="cursor-pointer" width="100%">
                <Box display="flex" gap="8px" alignItems="center" justifyContent="space-between">
                  <Typography variant="h5" noWrap onClick={() => moveToUsercommunityProfile(mentor?.userId)}>
                    {loading ? <MySkeleton width={forMobile ? 100 : 150} /> : getDisplayName(mentor)}
                  </Typography>

                  {forMobile ? <RoleTypeTag category={mentor?.category} loading={loading} /> : null}

                  {matchRequested && !matchRejected && (
                    <Box display="flex" gap="5px" justifyContent="end">
                      {loading ? (
                        <>
                          <MySkeleton variant="circular" height={20} width={20} />
                          <MySkeleton variant="circular" height={20} width={20} />
                        </>
                      ) : (
                        <>
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={() => acceptMatchRequest(mentor?.requestId, mentor?.mentorId, mentor?.menteeId)}
                            disabled={actionId === mentor?.requestId}
                          >
                            <CheckCircleIcon
                              color={actionId === mentor?.requestId ? 'secondary' : 'success'}
                              fontSize="small"
                            />
                          </IconButton>
                          <IconButton
                            sx={{ p: 0 }}
                            onClick={() =>
                              rejectMatchRequest(
                                mentor?.requestId,
                                mentor?.mentorId,
                                mentor?.menteeId,
                                mentor?.requestedcomId
                              )
                            }
                            disabled={actionId === mentor?.requestId}
                          >
                            <CancelIcon color="error" fontSize="small" />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  )}
                  {matchRejected &&
                    (loading ? (
                      <MySkeleton variant="rounded" height={20} width={60} />
                    ) : (
                      <Typography
                        px="9px"
                        py="2px"
                        variant="caption"
                        lineHeight="1"
                        color="error"
                        borderColor="error"
                        border="1px solid"
                        borderRadius="5px"
                        textAlign="center"
                        textTransform="capitalize"
                        className="cursor-default"
                      >
                        Request Rejected
                      </Typography>
                    ))}
                </Box>
                <Typography noWrap>
                  {loading ? (
                    <MySkeleton width={150} />
                  ) : (
                    <>
                      {mentor?.bio?.education?.major.concat(',') || mentor?.bio?.workHistory?.role.concat(', ')}
                      {mentor?.bio?.education?.university || mentor?.bio?.workHistory?.companyName}
                      {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. */}
                    </>
                  )}
                </Typography>
              </Box>
              {/* Request and Reject Match */}
            </Box>
          </Box>

          {/* Only For Mobile else dasktop */}
          {forMobile ? (
            !matchRequested && !matchRejected ? (
              !checkOrfium(oid, selectedGroupId?.id) && (
                <Box display="flex" alignItems="center" gap="8px">
                  {loading ? (
                    <>
                      <MySkeleton variant="circular" height={25} width={25} />
                      <MySkeleton variant="rounded" height={25} width={25} />
                    </>
                  ) : (
                    <>
                      <ChatIcon
                        size={21}
                        onClick={() => {
                          handleNavigateToChat(mentor);
                        }}
                      />
                      <VideoCallIcon
                        size={21}
                        onClick={(event) => {
                          handleCalendarOpen(mentor);
                        }}
                      />
                    </>
                  )}
                </Box>
              )
            ) : null
          ) : (
            <Box marginLeft="56px">
              <Box display="flex" gap="8px" mt={1} justifyContent="space-between">
                <Box display="flex" gap="8px">
                  <RoleTypeTag category={mentor?.category} loading={loading} />
                  {/* <Upvotes likes={85} icon='greenThumbsup' printArea='myMentorCard' /> */}
                </Box>

                {/* Match rejection remove */}
                {matchRejected &&
                  (loading ? (
                    <MySkeleton width={50} />
                  ) : (
                    <Link color="error" variant="caption" onClick={() => removeMatchRequest(mentor?.requestId)}>
                      Remove
                    </Link>
                  ))}
              </Box>

              {/* Match rejection Reason note */}
              {matchRequested || matchRejected ? (
                <Box mt={1} className="profile-text">
                  {loading ? (
                    <>
                      <MySkeleton />
                      <MySkeleton />
                    </>
                  ) : (
                    <Tooltip title={matchRejected ? rejectMessage : requestMessage}>
                      <Typography>{matchRejected ? rejectMessage : requestMessage}</Typography>
                    </Tooltip>
                  )}
                </Box>
              ) : (
                !checkOrfium(oid, selectedGroupId?.id) && (
                <Box display="flex" gap="8px" alignItems="center" mt={2}>
                  {loading ? (
                    <>
                      <MySkeleton variant="rounded" height={30} width={120} />
                      <MySkeleton variant="rounded" height={30} width={120} />
                    </>
                  ) : (
                    <>
                      <Button variant="outlined" className="bookCallBtn" onClick={() => handleNavigateToChat(mentor)}>
                        <ChatIcon size={21} />
                        Message
                      </Button>
                      <Button variant="outlined" className="bookCallBtn" onClick={() => handleCalendarOpen(mentor)}>
                        <VideoCallIcon size={21} />
                        Meeting
                      </Button>
                    </>
                  )}
                </Box>
                )
              )}
            </Box>
          )}

          {/* <Box>
            <IconButton sx={{p:0}}>
              <ShareIcon height={18} width={19} />
            </IconButton>
          </Box> */}
        </Box>

        {/* Chat with me Popup */}
        {/* <ChatWithMeDialog
          openChatWithMe={openChatWithMe}
          handleClickChatWithMe={handleClickChatWithMe}
        /> */}
      </>
    );
  }

  /** If this User no have any mentor  */
  function MentorNotFound() {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} padding={{ xs: 2, sm: 3 }}>
        {loading ? (
          <Box width={'100%'} textAlign={'center'} pt={{ sm: 3 }}>
            <MySkeleton width={'80%'} className="mx-auto" />
            <MySkeleton width={'80%'} className="mx-auto" />
          </Box>
        ) : (
          <Typography textAlign="center" pt={{ sm: 3 }}>
            You have not been matched with anyone yet. Contact your program administrator to be matched.
          </Typography>
        )}

        {/* <Button variant="contained" sx={{ px: 2 }}>
          Request a {mentorRoleTextLowerCase}
        </Button> */}
      </Stack>
    );
  }

  return (
    <>
      {/* my mentors section */}
      {!totalMentorsFound ? (
        <MentorNotFound />
      ) : (
        <Box px={1} pb={1} flex={1}>
          <Box className="mentorsCardSection" height={'100%'}>
            <Stack
              direction={forMobile ? 'column' : 'row'}
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              height={'100%'}
            >
              {totalMentorsCount &&
                _.uniqBy(totalMentors, 'displayName').map((each, index) => {
                  // only show 5 cards
                  return index < 5 ? <MentorCard key={index} mentor={each} /> : null;
                })}
            </Stack>
          </Box>
        </Box>
      )}

      {/* my mentors Booking Modal */}
      <CallBookingModal
        handleCloseModal={handleCalendarClose}
        openFlag={openCalendarModalVal}
        chatProfileName={displayName}
        threadParticipantsUsers={[participantUser]}
        chatType="Chats"
        pageName="Mentor"
        bookedCallDetails={bookedCallDetails}
      />

      {/* Request Match Reject dialog */}
      <RequestMatchReject
        openRequestMatchReject={openRequestMatchReject}
        handleRequestMatchReject={handleRequestMatchReject}
        requestId={requestId}
        groupId={groupId}
        mentorId={mentorId}
        menteeId={menteeId}
        doReload={doReload}
        commId={commId}
      />
    </>
  );
};

export default MyMentorCard;
