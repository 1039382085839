import {
  Box,
  Grid,
  Divider,
  TextField,
  IconButton,
  InputLabel,
  Typography,
  Autocomplete,
} from '@mui/material';
import { useAppSelector } from '../../../../../redux/hooks';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import { camelCaseToCapital } from '../../../../../utils/topicsMapping';
import { DeleteIcon } from '../../../../../utils/Icons';

const checkError = (fieldName: any, errors: any) => _.get(errors, `${fieldName}.message`, '');
const AddTaskForm = ({ control, indexValue, existingTasksLength, remove, formArrayErrors }: any) => {
  const groupData = useAppSelector((state) => state.groupDetails);
  const options = camelCaseToCapital(groupData?.data?.topics || []);
  const { mode } = useAppSelector((state) => state.userMenteeTask);

  return (
    <Box width={'100%'}>
      <Box display="flex" justifyContent="space-between">
        <Typography color={'#ABB5BE'} fontWeight={'700'}>Lesson {indexValue + 1}</Typography>
        <IconButton onClick={() => remove(indexValue)}>
          <DeleteIcon />
        </IconButton>
      </Box>

      <>
        <Box>
          <Grid container spacing={2}>
            <Controller
              name="new_taskId"
              control={control}
              //   defaultValue={taskId}
              render={({ field }) => <input type="hidden" {...field} />}
            />
            <Grid item xs={12}>
              <InputLabel>Lesson name *</InputLabel>
              <Controller
                name={`test.${indexValue}.taskName`} // Use a unique identifier for each task
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    placeholder="Lesson Name"
                    onChange={onChange}
                    value={value}
                    error={Boolean(checkError('taskName', _.get(formArrayErrors, `[${indexValue}]`, '')))}
                    helperText={checkError('taskName', _.get(formArrayErrors, `[${indexValue}]`, ''))}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Lesson Description *</InputLabel>
              <Controller
                name={`test.${indexValue}.taskDescription`} // Use a unique identifier for each task
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    multiline
                    rows={4}
                    // name="taskDescription"
                    placeholder="Lesson Description"
                    onChange={onChange}
                    value={value}
                    error={Boolean(checkError('taskDescription', _.get(formArrayErrors, `[${indexValue}]`, '')))}
                    helperText={checkError('taskDescription', _.get(formArrayErrors, `[${indexValue}]`, ''))}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Tags *</InputLabel>
              <Controller
                name={`test.${indexValue}.tags`} // Use a unique identifier for each task
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple
                    limitTags={2}
                    // id="addOtherUsers" // Make sure this ID is unique for each task
                    options={options}
                    getOptionLabel={(option) => (option ? _.startCase(option) : '')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Tags"
                        error={Boolean(checkError('tags', _.get(formArrayErrors, `[${indexValue}]`, '')))}
                        helperText={checkError('tags', _.get(formArrayErrors, `[${indexValue}]`, ''))}
                      />
                    )}
                    value={value}
                    onChange={(_event, newValue) => onChange(newValue)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ my: 2 }} />
      </>
    </Box>
  );
};

export default AddTaskForm;
