import React, { useEffect, useState } from 'react';
import {
  Box,
  Link,
  Grid,
  Stack,
  Divider,
  TextField,
  InputLabel,
  Typography,
  IconButton,
} from '@mui/material';
import _ from 'lodash';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import pencil from '../../../../../assets/images/pencil.svg';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { Controller } from 'react-hook-form';
import { API } from '../../../../../api';
// import { getUserDetails } from '../../../../../utils/orgName';
import AddTaskForm from './AddTaskForm';
import { DeleteIcon, EditIcon } from '../../../../../utils/Icons';

export const AddLevels = ({ selectedName, setValue, formArrayControl, formArrayFields, formArrayErrors }: any) => {
  const dispatch = useAppDispatch();
  const { mode } = useAppSelector((state) => state.userMenteeTask);
  // console.log(formArrayErrors, 'hhhhhhhhhhhhhhhhhhhh');
  // if (mode === 'addTask' && formArrayFields?.fields?.length === 0) {
  //   formArrayFields?.append({ taskName: '', taskDescription: '', tags: [] });
  // }
  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <InputLabel>Level Name *</InputLabel>
        <Controller
          name="levelName"
          control={formArrayControl}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              fullWidth
              name="levelName"
              placeholder="Level Name"
              onChange={(e) => {
                if (mode !== 'addTask') {
                  onChange(e); // Allow changes only when mode is not 'addTask'
                }
              }}
              value={mode === 'addTask' ? selectedName : value}
              error={Boolean(formArrayErrors?.levelName)}
              helperText={formArrayErrors?.levelName?.message}
            />
          )}
        />
        <Divider sx={{ my: 2 }} />
      </Grid>

      {_.map(formArrayFields?.fields, (eachField, index) => (
        <AddTaskForm
          key={eachField.id}
          control={formArrayControl}
          indexValue={index}
          existingTasksLength={0}
          remove={formArrayFields?.remove}
          formArrayErrors={formArrayErrors?.test}
        />
      ))}
      {(mode === 'add' || mode === 'addTask') && (
        <Link onClick={() => formArrayFields?.append({ taskName: '', taskDescription: '', tags: [] })} color="#0071A9" variant='caption'>+ Add Lesson</Link>
      )}
    </Grid>
  );
};

export const EditLevel = ({
  selectedLevelId,
  selectedName,
  setIsLoader,
  resetFlow,
  formArrayControl,
  formArrayFields,
  formArrayErrors,
  setDeletingIds
}: any) => {
  const [existingTasks, setExistingTasks] = useState<any[]>([]);
  const menteeTask = useAppSelector((state) => state?.goalTasks);
  const allTask = menteeTask?.data?.data;
  let allLevels = allTask?.[0]?.levels || [];
  const matchedLevel = allLevels.find((level: any) => level._id === selectedLevelId);
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const groupId = selectedGroupId.id || '';
  const matchedLevelTasks = matchedLevel?.tasks;
  // console.log(matchedLevelTasks, 'matched tasks');
  // console.log(selectedLevelId, 'set selected id');
  const existingTaskLevel = _.map(matchedLevelTasks, (each) => ({
    taskName: _.get(each, 'taskName', ''),
    taskDescription: _.get(each, 'taskDescription', ''),
    tags: _.get(each, 'tags', []),
    taskId: _.get(each, '_id', ''),
    isEditing: false
  }));

  useEffect(() => {
    setDeletingIds([]);
    formArrayFields.remove(Array.from({ length: formArrayFields?.fields?.length }, (value, idx) => idx));
    if (existingTaskLevel.length) {
      formArrayFields?.prepend(existingTaskLevel);
    }
  }, []);

  const handleDeleteTask = (levelId: any, taskId: any, index: any) => {
    // console.log(index, 'deleting index' + taskId);
    formArrayFields.remove(index);
    if (taskId) {
      setDeletingIds((prev: any) => [...prev, taskId]);
    }
    // goalTaskDelete(groupId, getUserDetails().location, levelId, taskId);
  };

  async function goalTaskDelete(groupId: any, location: string, levelId: any, taskId: any) {
    // setIsLoader(true);
    try {
      const response = await API.goalTaskDelete(groupId, location, levelId, taskId);
      return response; // Optionally return the response
    } catch (error) {
      // Handle errors
      // setIsLoader(false);
      console.error('Error creating lesson:', error);
      throw error; // Propagate the error if needed
    }
  }
  return (
    <Stack direction="column" spacing={2}>
      <Box>
        <InputLabel>Level Name *</InputLabel>
        <Controller
          name="levelName"
          control={formArrayControl}
          defaultValue={selectedName} // Set the initial value to details.title
          render={({ field }) => (
            <TextField
              fullWidth
              name="levelName"
              placeholder="Level Name"
              onChange={(e) => field.onChange(e.target.value)} // Update value on change
              value={field.value} // Display the current value
              error={Boolean(formArrayErrors?.levelName)}
              helperText={formArrayErrors?.levelName?.message}
            />
          )}
        />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
        {_.map(formArrayFields?.fields, (eachField, index) => {
          if (eachField.isEditing) {
            return (
              <AddTaskForm
                key={eachField.id}
                control={formArrayControl}
                indexValue={index}
                existingTasksLength={existingTasks?.length}
                remove={formArrayFields?.remove}
                formArrayErrors={formArrayErrors?.test}
              />
            );
          } else {
            return (
              <Box key={eachField.id}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography color={'#ABB5BE'} fontWeight={'700'}>Lesson {index + 1}</Typography>
                  <Box>
                    <IconButton onClick={() => formArrayFields.update(index, { ...eachField, isEditing: !eachField.isEditing })}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteTask(selectedLevelId, eachField?.taskId, index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>

                <Typography color="primary" width="400">{eachField?.taskName}</Typography>

                <Divider sx={{ my: 2 }} />
              </Box>
            );
          }
        })}
        <Link onClick={() => formArrayFields?.append({ taskName: '', taskDescription: '', tags: [], isEditing: true })} color="#0071A9" variant='caption' mt={2}>+ Add Lesson</Link>
      </Box>
    </Stack>
  );
};

export const AllLevels = () => {
  return (
    <Stack direction="column" spacing={2}>
      <Grid container>
        <Grid item sm={2}>
          <IconButton>
            <DragHandleIcon sx={{ color: '#ABB5BE' }} />
          </IconButton>
        </Grid>
        <Grid item sm={8}>
          <Typography color="#DF6438" fontWeight="700">
            Level 1
          </Typography>
          <Typography color="#000000" fontWeight="600" fontSize="16px" noWrap>
            Understanding your goal
          </Typography>
          <Typography color="#ABB5BE" fontSize="12px" fontWeight="400" noWrap>
            I think the simplest way is to create an object to define the font color and pass it
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <IconButton>
            <img
              src={pencil}
              alt="pencil"
              style={{
                padding: 0,
                margin: 0,
                width: '20px',
                height: '20px'
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item sm={2}>
          <IconButton>
            <DragHandleIcon sx={{ color: '#ABB5BE' }} />
          </IconButton>
        </Grid>
        <Grid item sm={8}>
          <Typography color="#ABB5BE" fontSize="14px" fontWeight="700">
            Level 2
          </Typography>
          <Typography noWrap color="#000000" fontSize="16px" fontWeight="600">
            Understanding your goal
          </Typography>
          <Typography noWrap color="#ABB5BE" fontSize="12px" fontWeight="400">
            I think the simplest way is to create an object to define the font color and pass it
          </Typography>
        </Grid>
        <Grid item sm={2}>
          <IconButton>
            <img
              src={pencil}
              alt="pencil"
              style={{
                padding: 0,
                margin: 0,
                width: '20px',
                height: '20px'
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Stack>
  );
};
