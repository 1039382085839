import { Box, Grid } from "@mui/material";
import MySkeleton from "../components/Skeleton";

/* Header */
export const TaskPreviewHeaderLoader = () => {
  return(
    <Box>
      <Box display='flex' justifyContent='flex-end'>
        <MySkeleton variant="rounded" height={20} width={20} className="m-8"/>
      </Box>
      <Box display='flex' justifyContent='space-between' alignItems='center' px={2} pb={1}>
        <MySkeleton width={100}/> 
        <MySkeleton variant="rounded" className='w-150px h-50px'/>
      </Box>
    </Box>
  )
}

/* Left Side Bar */
export const TaskPreviewLeftBarLoader = () => {
  return (
    <Box className="CardBox" pb={2} px={2} >
      {[...Array(5).keys()].map((each, idx, array) => {
        const isLastItem = idx === array.length - 1;

        return (
          <Box key={idx}>
            <Box display="flex" gap={'8px'} alignItems={'center'} my={2}>
              <Box width={'100%'}>
                <MySkeleton width={150} className="mb-20"/> 
                <Box display='flex' alignItems='center' gap={1} mb={0.5}>
                  <MySkeleton variant="rectangular" height={6} width={17} />
                  <MySkeleton width={100} />
                </Box>
                <MySkeleton width={200} className="ml-25"/>
              </Box>
              <Box>
                <MySkeleton variant="rectangular" height={20} width={3} />
              </Box>
            </Box>
            <Box mb={{ xs: 1, sm: 2 }} width={'100%'}>
            {[...Array(5).keys()].map(index => (
              <Box className="main-banner-sec ml-25" mb={0.5} key={index}>
                <MySkeleton variant="rectangular" height={6} width={15} />
                <MySkeleton width={200} />
              </Box>
            ))}
              <MySkeleton width={70} className="mt-10"/>
            </Box>
            {isLastItem ? null : <MySkeleton height={'2px'} />}
          </Box>
        );
      })}
    </Box>
  );
};

/* Right Side Bar */
export const TaskPreviewRightBarLoader = () => {
  return (
    <Box height={'100%'}>
      <Box px={2} className="CardBox" mb={2}>
        <Box gap={1} display='flex' justifyContent='space-between' textAlign={'center'} alignItems={'center'} pt={2} >
          <MySkeleton width={100} className="mb-10"/> 
          <MySkeleton variant="rounded" height={20} width={20} className="m-8"/>
        </Box>
        <Box position={'relative'}>
          <MySkeleton variant="rounded" height={97} />
        </Box>
        <MySkeleton height={'2px'} className='my-20' />

        {/* Add Task Questions */}
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <MySkeleton width={150}/> 
          <MySkeleton variant="circular" height={34} width={34} />
        </Box>
        <Box pb={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={7} md={8} sm={12}>
              <MySkeleton width={100} className="mb-10" />
              <MySkeleton variant="rounded" height={42} />
            </Grid>
            <Grid item xs={12} lg={4} md={4} sm={12}>
              <MySkeleton width={100} className="mb-10" />
              <MySkeleton variant="rounded" height={42} />
            </Grid>
            <Grid item xs={12} lg={1} md={12} sm={12} mt={3}>
              <MySkeleton width={50}/>
            </Grid>
          </Grid>
        </Box>

        <MySkeleton height={'2px'} />

        {/* Have you completed this lesson */}
        <Box>
          <Box py={2} display='flex' alignItems='center' gap={0.5}>
            <MySkeleton width={100}/>
          </Box>

          <Box pb={2} width={'50%'}>
            <MySkeleton variant="rounded" height={42} className="mb-10"/>
            <MySkeleton variant="rounded" height={42} />
          </Box>
        </Box>
      </Box>

      {/* Lesson Materials */}
      <Box className="CardBox" mb={2}>
        <Box my={1} px={2}>
          <MySkeleton variant="text" height={20} width={150} />
        </Box>
        <MySkeleton height="2px" />
        <Box className="resume-main-sec" gap={3}>
          {[...Array(3).keys()].map((each, idx) => {
            return (
              <Box display={'flex'} gap={2} key={idx}>
                <MySkeleton variant="rounded" height={75} width={118} />
                <Box>
                  <MySkeleton variant="text" height={24} width={150} />
                  <MySkeleton variant="text" height={24} width={150} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>

      {/* Lesson Mentors */}
      <Box className="CardBox" mb={2}>
        <Box my={1} px={2}>
          <MySkeleton variant="text" height={20} width={150} />
        </Box>
        <MySkeleton height="2px" />
        <Box className="resume-main-sec" justifyContent={'space-between'}>
          {[...Array(3).keys()].map((each, idx) => {
            return (
              <Box display={'flex'} flexDirection={'column'} gap={2} key={idx}>
                {idx !== 2 ? (
                  <Box display={'flex'} gap={1}>
                    <MySkeleton variant="circular" height={24} width={24} />
                    <MySkeleton variant="text" height={20} width={idx == 1 ? 200 : 100} />
                  </Box>
                ) : (
                  <Box pt={2} />
                )}
                <Box display={'flex'} gap={1}>
                  <MySkeleton variant="rounded" height={60} width={60} />
                  <Box>
                    <MySkeleton variant="text" height={20} width={50} />
                    <MySkeleton variant="text" height={20} width={200} />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>

      {/* Select topics relevant to this task */}
      <Box className='CardBox' my={2}>
        <Box px={2} pt={2} gap={.5} display='flex' alignItems={'center'}>
          <MySkeleton width={100}/> 
        </Box>
        <Box p={2}>
          <MySkeleton variant="rounded" height={42} />
        </Box>
      </Box>
    </Box>  
  )
};