import React from 'react';
import { Box, Typography } from '@mui/material';
import { getProgressingColor, roundOfNumber, useIsOrfium } from '../../components/common';
import { makeStyles } from '@mui/styles';
import ProgressBar from 'react-customizable-progressbar';

interface ProgressChartProps {
  percentage: number;
  avgProgress?: number;
}

const useStyles = makeStyles({
  progressContainer: {
    position: 'relative',
    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.20)',
    border: '2px solid #FFFFFF',
    borderRadius: "50%",
    overflow: "hidden"
  },
  progress: {
    marginTop: '5px',
    marginLeft: '1px'
  },
  insideBox: {
    position: 'absolute',
    top: "50%",
    left: "50%",
    transform: 'translate(-50%, -50%)',
    textAlign:'center'
  },
  secondProgress: {
    position: 'absolute',
    bottom: '8%',
    left: '124%',
    transform: 'translate(-100%, -90%)',
  }
});

const ProgressChart: React.FC<ProgressChartProps> = ({ percentage, avgProgress }) => {
  const classes = useStyles();
  const isOrfium =useIsOrfium();
  let percent = roundOfNumber(percentage);
  const getProgressingValue = percent < 1 ? '0' : percent;
  const setWidth = '160px';
  const setHeight = '160px';
  const progressingColor = '#11895E' //#getProgressingColor(getProgressingValue, avgProgress);

  return (
    <Box
      className={classes.progressContainer}
      width={setWidth}
      height={setHeight}
      bgcolor={progressingColor}
      sx={{textAlign: '-webkit-center'}}
    >
      {!isOrfium && (
        <ProgressBar
          progress={percentage}
          radius={65}
          steps={100}
          rotate={160}
          cut={140}
          strokeWidth={10}
          trackStrokeWidth={10}
          trackStrokeColor="#FFFFFF61"
          trackStrokeLinecap={'round'}
          pointerRadius={5}
          pointerStrokeWidth={3}
          pointerFillColor={progressingColor} //"#1AA179"
          pointerStrokeColor="#FFFFFF"
          strokeColor="#FFFFFF"
          className={classes.progress}
        />
      )}
      <Box className={classes.insideBox}>
        <Typography color="#FFFFFF" fontWeight={'600'} fontSize="34px" lineHeight={'normal'}>
          {getProgressingValue}<Typography color="#FFFFFF" variant='h5' component={'span'}>%</Typography>
        </Typography>
        <Typography color="#FFFFFF" noWrap lineHeight={'normal'}>
          My progress
        </Typography>
        {isOrfium && (
          <Typography variant='h5' color={'#FFFFFF'} fontWeight={'700'} mt={2}>
            Avg: {avgProgress ? avgProgress : 0}%
          </Typography>
        )}
      </Box>

      {/* <ProgressBar
        progress={51}
        radius={95}
        steps={100}
        rotate={433}
        cut={325}
        strokeWidth={5}
        trackStrokeWidth={5}
        trackStrokeColor="#FFFFFF61"
        trackStrokeLinecap={'round'}
        strokeColor="#FFFFFF"
        counterClockwise
        className={classes.secondProgress} 
      /> */}
    </Box>
  );
};

export default ProgressChart;
