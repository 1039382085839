import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { LiveChatStyle } from './LiveChatStyle';
import { useNavigate } from 'react-router-dom';
import { API } from '../../../../api';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup
  .object({
    Answer1: yup.string().required('This answer is required'),
    Answer2: yup.string().required('This answer is required'),
    Answer3: yup.string().required('This answer is required'),
    Answer4: yup.string().required('This answer is required')
  })
  .required();

const YesNoBtn = ({ answerGiven, answerRequired, handleAnswers }: any) => {
  const classes = LiveChatStyle();
  const backgroundColor = answerGiven === answerRequired ? '#200F0B' : '#EFF0F4';
  const textColor = answerGiven === answerRequired ? '#EFF0F4' : '#200F0B';
  return (
    <Button
      className={classes.btn}
      sx={{
        backgroundColor,
        marginRight: '10px',
        color: textColor,
        '&:hover': {
          backgroundColor
        }
      }}
      onClick={handleAnswers}
    >
      {answerRequired}
    </Button>
  );
};

const ChatBotQuestions: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      Answer1: '',
      Answer2: '',
      Answer3: '',
      Answer4: ''
    }
  });

  const submitHandler = async (values: any) => {
    setLoading(true);
    try {
      const response = await API.saveAIMentorQuiz({ answers: { ...values } });
      if (response.status === 200) {
        reset();
        navigate('/app/aithanks');
      }
    } catch (err) {
      toast.error(_.get(err, 'response.data.message', ''));
    } finally {
      setLoading(false);
    }
  };

  const getError = (fieldType: string) => (_.size(errors) ? String(_.get(errors, `${fieldType}.message`, '')) : '');

  return (
    <Box p={2}>
      <Box pb={2}>
        <Typography fontSize="16px" fontWeight="600">
          Dosen is developing an AI mentor service to help you along your personal development journey. Please take the
          5 question survey below to help us better understand what you would find most useful and how you may leverage
          an AI mentorship service like this. We appreciate your feedback.
        </Typography>
      </Box>

      <Box pb={2}>
        <Box pb={1}>
          <Typography variant="h6">Do you use ChatGPT or any other generative AI advice apps or features?</Typography>
        </Box>
        <Box>
          <Controller
            name="Answer1"
            control={control}
            render={({ field: { onChange, value } }) => {
              return <YesNoBtn answerGiven={value} answerRequired="Yes" handleAnswers={() => onChange('Yes')} />;
            }}
          />
          <Controller
            name="Answer1"
            control={control}
            render={({ field: { onChange, value } }) => {
              return <YesNoBtn answerGiven={value} answerRequired="No" handleAnswers={() => onChange('No')} />;
            }}
          />
        </Box>
        {getError('Answer1') ? (
          <p
            style={{
              color: 'rgb(220, 53, 69)',
              fontSize: '12px',
              fontWeight: 600,
              fontFamily: 'Open Sans',
              margin: '4px 14px 0px 0px'
            }}
          >
            {getError('Answer1')}
          </p>
        ) : null}
      </Box>

      <Box pb={2} width="70%">
        <Typography variant="h6" mb={1}>
          What do you use them for?
        </Typography>
        <Controller
          name="Answer2"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                multiline
                minRows={3}
                placeholder="Feedback..."
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={Boolean(getError('Answer2'))}
                helperText={getError('Answer2')}
              />
            );
          }}
        />
      </Box>

      <Box pb={2} width="70%">
        <Typography variant="h6" mb={1}>
          We’re going to build one for you here to help you complete the lessons in your program. What kind of things
          would you like it to be able to do / help you with?
        </Typography>
        <Controller
          name="Answer3"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                multiline
                minRows={3}
                placeholder="Feedback..."
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={Boolean(getError('Answer3'))}
                helperText={getError('Answer3')}
              />
            );
          }}
        />
      </Box>

      <Box pb={2} width="70%">
        <Typography variant="h6" mb={1}>
          What would be a good name for your AI mentor?
        </Typography>
        <Controller
          name="Answer4"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                multiline
                minRows={3}
                placeholder="Feedback..."
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={Boolean(getError('Answer4'))}
                helperText={getError('Answer4')}
              />
            );
          }}
        />
      </Box>

      <Box>
        <LoadingButton
          loading={loading}
          color="primary"
          variant="contained"
          type="submit"
          sx={{ height: '50px', px: '30px' }}
          onClick={handleSubmit(submitHandler)}
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ChatBotQuestions;
