import React from 'react';
import { Box, Typography } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { getProgressingColor } from '../../../components/common';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number, avgProgress?: number, width?: string }) {
  const setwidth = props.width ? props.width : 'auto';
  const getProgressingValue = Math.round(props.value);
  const progressingColor = getProgressingColor(getProgressingValue, props.avgProgress); // Goal progressing color

  return (
    <Box display="flex" alignItems="center" gap="8px" width={setwidth}>
      <Box width="100%">
        <LinearProgress
          {...props}
          variant="determinate"
          sx={{ '& .MuiLinearProgress-bar': { backgroundColor: progressingColor } }}
        />
      </Box>
      <Box minWidth={'35px'}>
        <Typography>{`${getProgressingValue}%`}</Typography>
      </Box>
    </Box>
  );
}

function LinearWithValueLabel() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
}
export default LinearProgressWithLabel;
