/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Link,
  Grid,
  Divider,
  Popover,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import {
  lockIcon,
  dummyAvatar,
  getBorderColor,
  getTrophyBadge,
  dummyMyMentorsList,
  formatNumberWithCommas,
  GroupBasedRoleFormatter,
  getGoalProgressShowOrNot
} from '../../components/common';
import {
  EditIcon,
  DeleteIcon,
  // AddCircleIcon
} from '../../../../utils/Icons';
import AvatarRow from './Avatars';
import { API } from '../../../../api';
import { toast } from 'react-toastify';
import MyMentorCard from './MyMentorCard';
import ProgressChart from './ProgressChart';
import { useAuth0 } from '@auth0/auth0-react';
import UserPeersPopup from './UserPeersPopup';
import Leaderboard from './cards/Leaderboard';
import { useNavigate } from 'react-router-dom';
import MenteeTaskPopup from './MenteeTaskPopup';
import GettingStartedCard from './GettingStartedCard';
import { useQueryClient } from '@tanstack/react-query';
import GettingStartedModal from './GettingStartedModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { levelTypes } from '../../../../utils/constants';
import MySkeleton from '../../../../components/Skeleton';
import { queryKeys } from '../../../../queries/queryKeys';
import { getUserDetails } from '../../../../utils/orgName';
import useMyMentors from '../../../../queries/useMyMentors';
import { CardStyle } from '../../style-components/CardStyling';
import community from '../../../../assets/images/directory.svg';
import useMenteePeers from '../../../../queries/useMenteePeers';
import DescriptionModal from '../../components/DescriptionModal';
import useGroupDetails from '../../../../queries/useGroupDetails';
import useGoalProgress from '../../../../queries/useGoalProgress';
import PreviewContent from '../Program/components/PreviewContent';
// import CheckInWithMentorDialog from './CheckInWithMentorDialog';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import useAllGroupTasks from '../../../../queries/useAllGroupTasks';
import { GetTasksLoader } from '../../../../Loader/MenteeHomeLoader';
import greycheckimg from '../../../../assets/images/greycheckimg.svg';
import greencheckimg from '../../../../assets/images/greencheckimg.svg';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { taskActions } from '../../../../redux/slices/goals-tasks/getTasksSlice';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import { checkOrfium, getOrfiumLevelName } from '../../../../utils/orfiumFunctions';
import { menteeTaskSlicAction } from '../../../../redux/slices/user-task/userMenteeTask';
import { chatProfileActions } from '../../../../redux/slices/chat-profile/chatProfileSlice';
import { updateXpPoints } from '../../../../redux/slices/menteeXpPoints/menteeXpPointsSlice';
import { fetchUserWorkHistory } from '../../../../redux/slices/user-work/userWorkHistorySlice';
import { fetchUserEducation } from '../../../../redux/slices/user-education/userEducationSlice';
import { fetchCommunityMembers } from '../../../../redux/slices/acs-community-members/acsCommunityMembers';

const Mentee = () => {
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const queryClient = useQueryClient();
  const mentorRoleText =
    selectedGroupId.id === '64fa3b0ef3cbc20ba20de466' ? 'Matches' : GroupBasedRoleFormatter('mentor', 'Mentors');
  const { user } = useAuth0();
  const { location, orgId } = getUserDetails();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classess = CardStyle();
  const selectedUserId = useAppSelector((state) => state.goalTasks.userId);
  const { gettingStarted } = useProfileDetails();

  const {
    refetch,
    isFetching,
    isLoading,
    data: newData,
    isStale
  } = useAllGroupTasks(selectedGroupId.id, selectedUserId);
  const goalProgress = useGoalProgress(newData?.data?.data[0]?.levels || []);
  const [selectedMember, setSelectedMember] = useState<any>(null);

  const [xpPoints, setXpPoints] = useState<any>();

  /** Getting Started Modal */
  const [openGettingStartedModal, setOpenGettingStartedModal] = useState(false);
  const [selectedStep, setSelectedStep] = useState(0);
  const handleClickGettingStartedModal = (flag: boolean) => {
    setOpenGettingStartedModal(flag);
  };

  const [goalProgressValue, setGoalProgressValue] = useState(0);
  const personalThreadsArr = useAppSelector((state) => state.acsChannels.personalThreadsArr);
  const [displayName, setDisplayName] = useState('');
  const [participantUser, setParticipantUser] = useState([]);
  const [reloadFlag, setReloadFlag] = useState(false);
  const communityMembers = useAppSelector((state) => state.acsCommunityUsers.data);
  const commList = useAppSelector((state) => state.getCommunityMembers.data);
  const groupId = selectedGroupId.id || '';
  const menteeTask = useAppSelector((state) => state?.goalTasks);
  const taskLoading = isLoading;
  const [taskData, setTaskData] = useState<any>([]);
  const { loading: loadingGoal, data: userGoals } = useAppSelector((state) => state.groupDetails) || {};
  const goalCount = Object.keys(userGoals?.goals ? userGoals?.goals : {}).length;
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const groupDetails = useAppSelector((state) => state?.groupDetails);
  /** Show goal progress */
  const [hasShowGoalProgressValue, setHasShowGoalProgressValue] = useState<any>(true);
  const showGoalProgress = getGoalProgressShowOrNot(hasShowGoalProgressValue);
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  const [isPeersPopupOpen, setPeersPopupOpen] = React.useState(false);
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);
  const isOrfium = checkOrfium(oid, selectedGroupId?.id);

  const {
    data: mentorsData,
    isLoading: mentorLoading,
    refetch: mentorsRefetch
  } = useMyMentors({
    grpId: groupId,
    orgId,
    location: getUserDetails().location
  });
  const {
    data: peersData,
    isLoading: peersLoading,
    refetch: peersRefetch
  } = useMenteePeers({
    grpId: groupId,
    orgId,
    location: getUserDetails().location
  });
  const loader = mentorLoading || peersLoading;

  const handlePeersPopup = (open: boolean) => {
    setPeersPopupOpen(open);
  };
  const handlePeersOpen = (open: boolean) => {
    setPeersPopupOpen(open);
  };

  const doReload = () => {
    queryClient.invalidateQueries({
      queryKey: [queryKeys.GET_MENTEE_PEERS, groupId]
    });
    queryClient.invalidateQueries({
      queryKey: [queryKeys.GET_MY_MENTORS, groupId]
    });
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [levelDescription, setLevelDescription] = useState('');
  const [levelNumber, setLevelNumber] = useState(0);
  const [levelFullName, setLevelFullName] = useState(0);
  const handleOpenDescription = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [selectedLevelId, setSelectedLevelId] = useState<any[]>([]);
  const [selectedLevelName, setSelectedLevelName] = useState('');
  const [rightTopPop, setRightTopPop] = useState(null);
  const rightTopDotsClick = (level: any) => (event: any) => {
    setEditModalOpen(true);
    setSelectedLevelId(level._id);
    setSelectedLevelName(level.levelName);
    setRightTopPop(event.currentTarget);
  };
  const handleClose = () => {
    setRightTopPop(null);
    setEditModalOpen(false);
  };
  const openMoreVerts = Boolean(rightTopPop);
  const rightTopPopover = openMoreVerts ? 'simple-popover' : undefined;
  const avatars = peersData?.data.data[0]?.menteeDetails.map((each: any) => ({ avatar: each?.headshot, firstName: each?.displayName }));
  const groupList = useAppSelector((state) => state.groupRoleList);
  const moveToUsercommunityProfile = (userId: string) => {
    const mem = communityMembers.find((item: { userId: string }) => item.userId === userId);
    const modifiedMem = { ...mem, id: _.get(mem, 'id.coId', '') };
    if (mem) {
      navigate('/app/communitymember', {
        state: { mem: modifiedMem, allCommunityMembersList: commList }
      });
    }
  };

  const getMenteesInsights = async () => {
    const menteeInsightsData = await API.getMenteeInsights(orgId, location, groupId);
    if (menteeInsightsData) {
      setXpPoints(menteeInsightsData?.data?.xpPoints);
      dispatch(updateXpPoints(menteeInsightsData?.data?.xpPoints));
      setGoalProgressValue(menteeInsightsData?.data?.goalProgress);
      setHasShowGoalProgressValue(menteeInsightsData?.data?.showGoalProgress);
    }
  };

  const getChannels = () => {
    dispatch(
      fetchCommunityMembers({
        orgId: getUserDetails().orgId,
        location: getUserDetails().location
      })
    );
  };

  const [helpfulTips, setHelpfulTips] = useState([]);
  const [articleData, setArticleData] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [articleDataLoading, setArticleDataLoading] = useState(false);
  const [mentorshipHoursValue, setMentorshipHours] = useState('00:00');
  const [mentorRoi, setMentorRoi] = useState<any>(0);

  const handelOnClickPreviewArticleName = async (articleId: string, location: string) => {
    setArticleDataLoading(true);
    setOpenPreview(true);
    try {
      const response = await API.getAllArticleById({
        orgId: orgId,
        articleId: articleId,
        isContentSettings: false,
        articleLocation: location ? location : ''
      });
      if (response && response.status === 200) {
        setArticleData(response.data.article);
      }
    } catch (error) {
      toast.error(_.get(error, 'response.data.message', 'Something went wrong, please try again'));
      setOpenPreview(false);
    } finally {
      setArticleDataLoading(false);
    }
  };

  const getMenteeHelpfulTips = async () => {
    const response = await API.getHelpfulTips({ groupId: groupId, role: 'mentee', location });
    if (response.status === 200) {
      setMentorshipHours(response?.data?.mentorshipHours);
      const formattedMentorROI = new Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 1
      }).format(response?.data?.mentorROI);

      setMentorRoi(formattedMentorROI);
      setHelpfulTips(response?.data?.data);
    }
  };

  useEffect(() => {
    getMenteeHelpfulTips();
  }, [groupId]);

  useEffect(() => {
    if (groupDetails.data) {
      const allowEditing = groupDetails?.data?.includeTask && groupDetails?.data?.allowEditing ? true : false;
      if (!gettingStarted.editTasks && allowEditing) {
        setOpenGettingStartedModal(true);
      }
      if (!gettingStarted.nonEditTasks && !allowEditing) {
        setOpenGettingStartedModal(true);
      }
    }
  }, [groupId, gettingStarted, groupDetails.data]);

  useEffect(() => {
    getChannels();
  }, []);

  useEffect(() => {
    if (menteeTask) {
      setTaskData(newData?.data?.data);
    } else {
      setTaskData([]);
    }
  }, [newData]);

  const { refetch: fetchGroupDetails } = useGroupDetails(groupId, getUserDetails().location);

  useEffect(() => {
    try {
      if (groupId) {
        fetchGroupDetails();
      }
    } catch (error) { }
  }, [groupId]);

  const saveAnswer = useAppSelector((state) => state.saveAnswer.flag);
  useEffect(() => {
    if (isStale && !saveAnswer) {
      refetch();
    }
  }, [selectedGroupId.id, selectedUserId, saveAnswer, isStale]);

  useEffect(() => {
    if (selectedMember?.userId) {
      dispatch(fetchUserWorkHistory(selectedMember?.userId));
      dispatch(fetchUserEducation(selectedMember?.userId));
    }
    if (groupId) {
      if (selectedGroupId.role === 'mentee') {
        dispatch(taskActions.setUserId({ userId: '' }));
      }
    }
  }, []);

  const getName = (each: any) => {
    const chatProfileFirstName: any = _.get(each, 'firstName', '');
    const chatProfileLastName: any = _.get(each, 'lastName', '');
    return chatProfileFirstName
      ?.charAt(0)
      ?.toUpperCase()
      ?.concat(chatProfileFirstName?.slice(1)?.toLowerCase())
      ?.concat(
        ' ',
        chatProfileLastName?.charAt(0)?.toUpperCase()?.concat(chatProfileLastName?.slice(1)?.toLowerCase())
      );
  };

  const navigateMember = (member: any) => {
    setSelectedMember(member);
  };

  /** Calendar modal */
  const handleCalendarOpen = (member: any) => {
    setDisplayName(member.displayName);
    setParticipantUser(member);
    // setPeersOpen(false);
  };

  const navigateToChat = () => {
    let communicationId = _.get(selectedMember, 'communicationUserId', '');
    if (!communicationId) {
      communicationId = _.get(selectedMember, 'communicationId', '');
    }
    const channel = personalThreadsArr?.find((each) => each?.displayNames?.includes(communicationId));
    dispatch(chatProfileActions.atnSetChatProfileState(null));
    if (communicationId && channel) {
      navigate(`/app/chat/?threadid=${_.get(channel, 'id')}&type=Chats&name=${_.get(channel, 'topic')}`);
    } else if (communicationId && !channel) {
      navigate(`/app/chat/?commId=${communicationId}`);
    }
  };

  useEffect(() => {
    if (selectedMember) {
      navigateToChat();
    }
  }, [selectedMember]);

  useEffect(() => {
    if (typeof groupId === 'string' && groupId !== '') {
      queryClient.fetchQuery({
        queryKey: [queryKeys.GET_MENTEE_PEERS, groupId]
      });
      getMenteesInsights();
      queryClient.fetchQuery({
        queryKey: [queryKeys.GET_MY_MENTORS, groupId]
      });
    }
  }, [groupId, reloadFlag]);

  if (groupList.error) {
    return <Box>{groupList.errorText}</Box>;
  }

  if (groupList.data?.length === 0) {
    return <Box>No Groups found</Box>;
  }

  const handleAddLevels = () => {
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: true, mode: 'add' }));
  };

  const handleAddTasks = (level: any) => {
    dispatch(menteeTaskSlicAction.setSelectedLevel({ selectedLevel: level.levelName, selectedLevelId: level._id }));
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: true, mode: 'addTask' }));
  };

  const handleEditLevel = () => {
    dispatch(
      menteeTaskSlicAction.setSelectedLevel({
        selectedLevel: selectedLevelName,
        selectedLevelId: selectedLevelId
      })
    );
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: true, mode: 'edit' }));
    // handleClose();
  };

  const handleDeleteLevel = () => {
    dispatch(
      menteeTaskSlicAction.setSelectedLevel({
        selectedLevelId: selectedLevelId
      })
    );
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: true, mode: 'delete' }));
    // handleClose();
  };
  const currentDate = Date.now() / 1000;

  function getAvailabilityMessage(startDate: any, endDate: any, type: string, dateType?: string) {
    let color = '';
    let text = '';
    if (!startDate && !endDate) {
      text = '';
    } else if (startDate && !endDate) {
      if (currentDate >= startDate) {
        text = '';
      } else {
        text = `Available on ${moment.unix(startDate).format('DD/MM/YYYY')} `;
      }
    } else if (currentDate < startDate) {
      text = `Available on ${moment.unix(startDate).format('DD/MM/YYYY')} `;
    } else if (currentDate >= startDate && currentDate <= endDate) {
      const timeLeft = endDate - currentDate;
      if (timeLeft <= 7 * 24 * 3600) {
        // 7 days in seconds
        color = 'orange';
      } else {
        color = '';
      }
      text = `Due by ${moment.unix(endDate).format('DD/MM/YYYY')}`;
    } else if (currentDate > endDate) {
      color = 'red';
      text = `Due by ${moment.unix(endDate).format('DD/MM/YYYY')}`;
    } else {
      text = '';
    }

    if (type === 'level') {
      return (
        <Typography fontSize={'12px'} fontStyle={'italic'} color={dateType === levelTypes.availableSoon ? '' : color}>
          {dateType === levelTypes.availableSoon ? 'Available soon' : text}
        </Typography>
      );
    }

    return (
      <Typography
        bottom={'-12px'}
        fontSize={'12px'}
        lineHeight={'14px'}
        fontStyle={'italic'}
        position={'absolute'}
        color={color}
      >
        {text}
      </Typography>
    );
  }

  const getIsTaskStarted = (startDate: any) => {
    if (!startDate || currentDate >= startDate) {
      return true;
    }
    return false;
  };

  const getLevelText = (level: any) => {
    if (level?.startDate?.type === levelTypes.availableSoon) {
      return 'soon';
    }
    return `on ${moment.unix(level?.startDate.value).format('DD/MM/YYYY')}`;
  };

  const getTasks = (each: any) => {
    return (
      <>
        {each?.map((level: any, index: any) => {
          const levelColor = getLevelActiveDate(level) ? 'green' : level.levelColor;
          return (
            <Box className="MylevelsCard">
              <Box
                borderRadius="8px 8px 0 0"
                borderTop={`10px solid ${getBorderColor(levelColor, getLevelActiveDate(level))}`}
              />
              <Box>
                <Box px={2} pt={2} pb={1} display="flex" justifyContent="space-between" alignItems="center">
                  <Box width="calc(100% - 45px)">
                    <Typography color="secondary.light" variant="h6">
                      {getOrfiumLevelName(oid, selectedGroupId?.id, index + 1)}
                    </Typography>
                    <Typography variant="h5" noWrap>
                      {level.levelName}
                    </Typography>
                    {/* Level end date print */}
                    <Box height={'14px'}>
                      {level?.endDate?.value || level?.startDate?.value
                        ? getAvailabilityMessage(
                          level?.startDate?.value,
                          level?.endDate?.value,
                          'level',
                          level?.startDate?.type
                        )
                        : null}
                    </Box>
                  </Box>
                  <img
                    src={getTrophyBadge(level.hexColor, getLevelActiveDate(level))}
                    alt="Trophybadge"
                    width="35px"
                    height="35px"
                  />
                  {!level?.level && (
                    <Box>
                      <MoreVertIcon
                        color="primary"
                        className="cursor-pointer"
                        onClick={(event) => rightTopDotsClick(level)(event)}
                      />
                    </Box>
                  )}
                </Box>

                <Divider />

                {getLevelActiveDate(level) ? (
                  <Box p={2}>
                    {level.tasks.map((task: any, index: number) => {
                      return (
                        <React.Fragment key={task._id}>
                          <Box
                            gap="15px"
                            width={'100%'}
                            display="flex"
                            alignItems="center"
                            onClick={() => {
                              if (getIsTaskStarted(task?.startDate)) {
                                navigate(`/app/home/mentee/level/${level._id}/task/${task._id}`);
                              }
                            }}
                            sx={{
                              cursor: getIsTaskStarted(task?.startDate) ? 'pointer' : 'not-allowed'
                            }}
                          >
                            <img
                              src={task.taskColor === 'green' ? greencheckimg : greycheckimg}
                              alt="greencheckimg"
                              width="20px"
                              height="20px"
                            />
                            <Box flex={1} position={'relative'} width={'calc(100% - 35px)'}>
                              <Typography
                                noWrap
                                className={getIsTaskStarted(task?.startDate) ? 'cursor-pointer' : 'cursor-not-allowed'}
                                color={task.taskColor === 'green' ? 'secondary' : '#0071A9'}
                              >
                                {task.taskName}
                              </Typography>
                              {getAvailabilityMessage(task?.startDate, task?.endDate, 'task')}
                            </Box>
                          </Box>

                          {index !== level.tasks.length - 1 && (
                            <Box borderLeft="1px dotted #D9D9D9" ml="9.5px" py="10px" />
                          )}
                        </React.Fragment>
                      );
                    })}
                  </Box>
                ) : (
                  <Box p={2} mt={2} textAlign={'center'}>
                    {lockIcon({ size: '35px', color: '#68717A' })}
                    <Typography mt={1}>This level will be available {getLevelText(level)}</Typography>
                    <Link
                      color={'info.main'}
                      fontStyle={'italic'}
                      onClick={() => {
                        setLevelNumber(index + 1);
                        setLevelFullName(level?.levelName || 'No Level Name');
                        setLevelDescription(level?.levelDescription || 'This level has no description');
                        handleOpenDescription();
                      }}
                    >
                      Read description of Level {index + 1}
                    </Link>
                  </Box>
                )}
              </Box>
              {!level?.level && (level?.startDate?.type === levelTypes.activeNow || level?.startDate === undefined) && (
                <Box pb={2} pl={2}>
                  <Link onClick={() => handleAddTasks(level)} color={'info.main'} variant="caption">
                    + Add Lesson
                  </Link>
                </Box>
              )}
            </Box>
          );
        })}

        {/* edit or delete modal */}
        <Popover
          id={rightTopPopover}
          open={editModalOpen}
          anchorEl={rightTopPop}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Box className="PopoverListStyle" gap="8px" onClick={() => handleEditLevel()}>
            <EditIcon />
            <Typography color="primary">Edit Level</Typography>
          </Box>
          <Box className="PopoverListStyle" onClick={() => handleDeleteLevel()} gap="8px">
            <DeleteIcon />
            <Typography color="primary">Delete Level</Typography>
          </Box>
        </Popover>
      </>
    );
  };

  const getLevelActiveDate = (level: any) => {
    if (level?.startDate?.type === levelTypes.activeNow || level?.startDate === undefined) {
      return true;
    }
    if (level?.startDate?.type === levelTypes.availableSoon) {
      return false;
    }

    if (level?.startDate?.type === levelTypes.calendarDate && currentDate >= level?.startDate?.value) {
      return true;
    }
    if (level?.startDate?.type === levelTypes.daysAfterSignup && currentDate >= level?.startDate?.value) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Box className="bodyBox">
        <Box className={isOrfium ? 'flex-column-reverse' : ''}>
          {/* Sections cards */}
          <Grid container spacing={{ xs: 1, sm: 2 }} mb={!isOrfium ? { xs: 1, sm: 2 } : 0}>
            {/* My Goal */}
            <Grid item xs={12} md={isOrfium ? 4 : 6} lg={isOrfium ? 3 : 4.5}>
              <Box className={classess.cardBox} minHeight={forMobile ? 'auto' : '240px'}>
                <Box p={2}>
                  <Typography variant="h5">{loader ? <MySkeleton width={100} /> : 'My Progress'}</Typography>
                </Box>
                <Divider />
                <Box className="mygoalBox" justifyContent={isOrfium ? 'center' : ''}>
                  {/* Goal progress chart */}
                  {showGoalProgress && (
                    <Box>
                      {loader ? (
                        <MySkeleton variant="circular" height={160} width={160} />
                      ) : (
                        <ProgressChart percentage={goalProgress} avgProgress={peersData?.data?.data[0]?.avgGoalProgress || 0}/>
                      )}
                    </Box>
                  )}
                  {/* Peers */}
                  {!isOrfium && (
                    <Box>
                      <Box className="d-desktop">
                        <Box display="flex" alignItems="center" gap="8px" width="100%">
                          {loader ? (
                            <MySkeleton variant="circular" height={50} width={50} />
                          ) : (
                            <Box
                              width="50px"
                              height="50px"
                              borderRadius="50%"
                              border="2px solid #EFF0F4"
                              overflow="hidden"
                              padding="8px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <img src={community} alt="logo" width="28px" height="28px" />
                            </Box>
                          )}
                          <Typography variant="h1" fontSize="40px" textAlign="center">
                            {loader ? (
                              <MySkeleton variant="rectangular" height={50} width={50} />
                            ) : (
                              formatNumberWithCommas(peersData?.data.data[0]?.menteeDetails?.length)
                            )}
                          </Typography>
                          <Typography variant="h6" color="secondary">
                            {loader ? <MySkeleton width={50} /> : 'Peers'}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center" gap="8px" my={1} flexWrap={'wrap'}>
                        <Box>
                          <AvatarRow avatars={loader ? dummyAvatar : avatars} loading={loader} />
                        </Box>
                        {peersData?.data.data?.length > 0 && (
                          <Typography
                            color="#0071A9"
                            fontSize="14px"
                            fontWeight={400}
                            className="cursor-pointer"
                            onClick={() => handlePeersOpen(!loader)}
                          >
                            {loader ? <MySkeleton width={150} /> : 'View all peers'}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>

            {/* My mentor */}
            {!isOrfium && (
              <Grid item xs={12} md={6} lg={4.5}>
                <Box className={`${classess.cardBox} ${classess.flexColumn}`} minHeight={forMobile ? 'auto' : '240px'}>
                  <Box p={2}>
                    <Typography variant="h5">
                      {loader ? (
                        <MySkeleton width={150} />
                      ) : checkOrfium(oid, selectedGroupId?.id) ? (
                        'My Administrator'
                      ) : (
                        `My ${mentorRoleText}`
                      )}
                    </Typography>
                  </Box>
                  <Divider />
                  <MyMentorCard
                    myMentorList={loader ? dummyMyMentorsList : mentorsData?.data.data}
                    loading={loader}
                    myRequestedMentorList={loader ? dummyMyMentorsList : mentorsData?.data.requestedMentors}
                    doReload={doReload}
                  />
                </Box>
              </Grid>
            )}

            {/* Leaderboard */}
            {isOrfium && (
              <Grid item xs={12} md={8} lg={6}>
                <Leaderboard
                  data={peersData?.data?.data[0]?.menteeDetails}
                  loader={isLoading}
                />
              </Grid>
            )}

            {/* Getting started card */}
            <Grid item xs={12} md={12} lg={3}>
              <GettingStartedCard
                loading={loader}
                handleClickGettingStartedModal={handleClickGettingStartedModal}
                setSelectedStep={setSelectedStep}
                helpfulTips={helpfulTips}
                handelOnClickPreviewArticleName={handelOnClickPreviewArticleName}
              />
            </Grid>
          </Grid>

          {/* Task cards */}
          <Box className="CardBox" mb={isOrfium ? { xs: 1, sm: 2 } : 0}>
            <Box p={2} gap="8px" display="flex" alignItems="center" flexWrap={'wrap'}>
              <Typography variant="h5" noWrap>
                {isOrfium
                  ? 'Orfium Manager Effectiveness Program'
                  : taskData?.[0]?.goalName || 'My personal development plan'}
              </Typography>
              {goalCount > 0 && (
                <Link onClick={handleAddLevels} color="info.main" variant="h6" noWrap>
                  {groupDetails?.data?.includeTask ? (groupDetails?.data?.allowEditing ? '+ Add Level' : null) : null}
                  {/* <AddCircleIcon color='blue' />{' '}Add a learning objective  */}
                </Link>
              )}
              <MenteeTaskPopup setReloadFlag={setReloadFlag} />
            </Box>
            <Divider />
            {taskLoading ? (
              <GetTasksLoader />
            ) : taskData?.length ? (
              <>
                {taskData?.map((eachLevel: any) => {
                  return (
                    <Box
                      px={2}
                      key={eachLevel}
                      pb={forMobile ? (eachLevel?.levels?.length > 1 ? 2 : 0) : eachLevel?.levels?.length > 3 ? 2 : 0}
                    >
                      {eachLevel?.levels.length > 0 ? (
                        <Box className="MylevelsCardMain">{getTasks(eachLevel.levels)}</Box>
                      ) : (
                        <Typography textAlign="center" py={2}>
                          No lesson assigned
                        </Typography>
                      )}
                    </Box>
                  );
                })}
              </>
            ) : (
              <Box p={2}>
                <Typography textAlign="center">No lesson assigned</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* Group of Peers Modal */}
      {isPeersPopupOpen && (
        <UserPeersPopup
          open={isPeersPopupOpen}
          onClose={() => handlePeersPopup(false)}
          peersList={peersData?.data.data[0]?.menteeDetails}
          onProfileClick={(mid) => moveToUsercommunityProfile(mid)}
          onChatClick={(each) => navigateMember(each)}
          onCalendarClick={(each) => handleCalendarOpen(each)}
          communityMembers={communityMembers} // Pass the communityMembers prop here
          navigate={navigate} // Pass the navigate prop here
        />
      )}

      {/* Check in with Mentor Modal */}
      {/* <CheckInWithMentorDialog
        checkInWithMentor={checkInWithMentor}
        handleCheckInWithMentor={handleCheckInWithMentor}
      /> */}

      <GettingStartedModal
        open={openGettingStartedModal}
        handleClickGettingStartedModal={handleClickGettingStartedModal}
        allowEditing={groupDetails?.data?.includeTask && groupDetails?.data?.allowEditing ? true : false}
        selectedStep={selectedStep}
      />

      {/* Description Modal */}
      <DescriptionModal
        open={isModalOpen}
        onClose={handleCloseModal}
        // title={`Level ${levelNumber} Description`}
        title={`${levelFullName}`}
        message={levelDescription}
      />

      {/* Preview content for Getting Started */}
      <PreviewContent
        openPreview={openPreview}
        setOpenPreview={setOpenPreview}
        singleListData={articleData}
        loading={articleDataLoading}
      />
    </>
  );
};

export default Mentee;
