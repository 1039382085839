/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Stack,
  Dialog,
  Button,
  Divider,
  Checkbox,
  Accordion,
  Typography,
  DialogActions,
  DialogContent,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { API } from '../../../api';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchUserWorkHistory } from '../../../redux/slices/user-work/userWorkHistorySlice';
import { getUserDetails } from '../../../utils/orgName';
import ChatProfile from './chat/chat-main/ChatProfile';
import { fetchUserEducation } from '../../../redux/slices/user-education/userEducationSlice';
import { useAuth0 } from '@auth0/auth0-react';
import CommunitySidebar from './CommunitySidebar';
import '../../../assets/style/sass/community.scss';
// import SimilarProfiles from './SimilarProfiles';
import _ from 'lodash';
import { fetchCommunityMember } from '../../../redux/slices/communityMembers/communityMembersSlice';
import useUserDetailsById from '../../../queries/useUserDetailsById';
import { userIdUpdate } from '../../../redux/slices/userId/userIdSlice';
import { communityFilterAction } from '../../../redux/slices/community-filters/communityFilterSlice';
import { toast } from 'react-toastify';
import useResponsiveScreenSwitch from '../../../utils/useResponsiveScreenSwitch';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';
import { CommunityLoaderLeft, CommunityLoaderRight } from './community/CommunityLoader';
import { AGILE_ALLOWED_CATEGORY_COMMUNITY } from '../../../utils/constants';
import {
  IsAgileExecutive,
  customerCategoryArray,
  industryArray,
  rolesArray,
  topicArray,
  sortedCountries as countries,
  formatNumberWithCommas
} from '../components/common';
import { FilterCheckField, FilterSearch } from '../components/filter/FilterProperty';
import { FilterIcon } from '../../../utils/Icons';
import FilterLoader from '../components/filter/FilterLoader';
import ROLES from '../../../utils/roles';
import { camelCaseToCapitalIndustries, capitalToCamelCaseIndustries } from '../../../utils/industriesMapping';

const CommunityMember = () => {
  const [selectedMember, setSelectedMember] = useState<any>({});
  const urlLocation = useLocation();
  const filters = urlLocation.state?.filters; // retrieving filters from community page
  const dispatch = useAppDispatch();
  const refrence: React.RefObject<HTMLInputElement> = useRef(null);
  const { state } = useLocation();
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const { user } = useAuth0();
  const { orgId, location } = getUserDetails();
  const communityMembers = useAppSelector((state) => state.getCommunityMembers);
  const communityFilters = useAppSelector((state) => state.communityFilters.data);
  const categoryUserType = useAppSelector((state) => state?.orgDetails.data?.orgType) || [];
  const [reset, setReset] = useState(false);

  const [openFilter, setOpenFilter] = React.useState(false);
  const [allCommunityMembersList, setAllCommunityMembersList] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [openBookTime, setOpenBookTime] = React.useState(false);
  const [applyFilter, setApplyFilter] = useState(true);
  const userWorkHistory = useAppSelector((state) => state.userWorkHistory);
  const userEducation = useAppSelector((state) => state.userEducation);
  const [selectedRole, setSelectedRole] = useState<string[]>([]);
  const [selectedUserType, setSelectedUserType] = useState<string[]>([]);
  const [selectedIndustry, setselectedIndustry] = useState<string[]>([]);
  const [selectedRoleType, setselectedRoleType] = useState<string[]>([]);
  const [selectedHobbies, setselectedHobbies] = useState<string[]>([]);
  const [selectedBsIndustries, setSelectedBsIndustries] = useState<string[]>([]);
  const [hobbiesList, setHobbiesList] = useState<string[]>([]);
  const [industryList, setIndustryList] = useState<string[]>([]);
  const [roleTypeList, setRoleTypeList] = useState<string[]>([]);
  const [searchSkillTerm, setSearchSkillTerm] = useState<string>('');
  const data = useUserDetailsById();
  const userDetails = data?.data?.data?.data;
  const selectedUserId = useAppSelector((state) => state.userId.data);
  const isAgileExec = IsAgileExecutive();
  let roles: any[] = rolesArray();
  const orgDetails = useAppSelector((state) => state.orgDetails.data?.userTypes);
  let customerCategory: any[] = orgDetails || [];
  const [bsIndustriesList, setBsIndustriesList] = useState<string[]>([]);
  const uniqueBsIndustrieslist = [...new Set(bsIndustriesList)];

  /** For responsive */
  const { forMobile, mobileBackBtn } = useResponsiveScreenSwitch(); // Use the hook

  const [countryList, setCountryList] = useState(countries);
  const [searchCounrty, setSearchCountry] = useState<string>('');
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);

  const handleCountryChange = (country: string) => {
    setSelectedCountries((prevCountry) =>
      prevCountry?.includes(country) ? prevCountry?.filter((c) => c !== country) : [...(prevCountry || []), country]
    );
  };

  const handleSearchCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCountry(event.target.value);
    const { value } = event.target;
    const filteredCountries = countries.filter((country) => country.label.toLowerCase().includes(value.toLowerCase()));
    setCountryList(filteredCountries);
  };

  const handleSearchSkills = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchSkillTerm(event.target.value);
  };
  const topics = topicArray();
  const topicsList = roleTypeList.map((tag) => {
    const index = topics.findIndex((each) => each.name.toLowerCase() === tag.toLowerCase());
    if (index !== -1) {
      return { label: topics[index].label, name: topics[index].name };
    }
    return { label: tag, name: tag };
  });
  // basic settings industries
  const industries = industryArray();
  const filteredBsIndustriesList = uniqueBsIndustrieslist.map((tag) => {
    const index = industries.findIndex((each) => each.name.toLowerCase() === tag.toLowerCase());
    if (index !== -1) {
      return { label: industries[index].label, name: industries[index].name };
    }
    return { label: tag, name: tag };
  });

  const filteredRoleTypes = topicsList.filter((roleType) =>
    roleType.name.toLowerCase().includes(searchSkillTerm.toLowerCase())
  );
  const [showCompany, setShowCompany] = useState(false);
  const role = localStorage.getItem('role') || '';
  const isPlatformAdmin = role === ROLES.platAdmin;
  const isOrgAdmin = role === ROLES.orgAdmin;

  useEffect(() => {
    if (communityMembers?.data) {
      const isCompanyExist = communityMembers.data.some((each) =>
        each?.company && ![ROLES.orgAdmin, ROLES.platAdmin].includes(each.roleType) ? true : false
      );
      if (isCompanyExist) {
        setShowCompany(true);
      } else {
        setShowCompany(false);
      }
    }
  }, [communityMembers]);

  const uniqueRoleTypes = [...new Set(filteredRoleTypes)];

  const handleRoleChange = (role: string) => {
    setSelectedRole((prevRole) =>
      prevRole?.includes(role) ? prevRole?.filter((rol) => rol !== role) : [...(prevRole || []), role]
    );
  };

  const handleBsIndustriesChange = (type: string) => {
    const index = industries.findIndex((each) => each.label === type);
    const name = index !== -1 ? industries[index].name : '';
    setSelectedBsIndustries((prevTypes) =>
      prevTypes.includes(name) ? prevTypes.filter((t) => t !== name) : [...prevTypes, name]
    );
  };

  const fetchSkillsTopic = async () => {
    try {
      const response = await API.getAllTopicsForUser(location);
      if (response.status === 200) {
        return (
          response?.data?.topics?.sort()?.map((topic: any) => topic?.charAt(0)?.toUpperCase() + topic?.slice(1)) || []
        );
      }
    } catch {
      return [];
    }
  };

  const fetchIndustries = async () => {
    try {
      const response = await API.getAllIndustriesForUser(location);
      if (response.status === 200) {
        return (
          // response?.data?.industries?.sort()?.map((topic: any) => topic?.charAt(0)?.toUpperCase() + topic?.slice(1)) ||
          // []
          response?.data?.industries?.sort() || []
        );
      } else {
        return [];
      }
    } catch {
      return [];
    }
  };

  useEffect(() => {
    Promise.all([
      fetchTagList('Hobbies and Interests'),
      fetchTagList('industry'),
      fetchSkillsTopic(),
      fetchIndustries()
    ]).then(([tagList1, tagList2, tagList3, tagList4]) => {
      setHobbiesList(tagList1);
      setIndustryList(camelCaseToCapitalIndustries(tagList4));
      setRoleTypeList(tagList3);
      setBsIndustriesList(tagList4);
    });
    // dispatch(
    //   fetchCommunityMember({
    //     orgId,
    //     location
    // role: communityFilters?.selectedRole || [],
    // category: communityFilters?.selectedUserType || [],
    // industry: communityFilters?.selectedIndustry || [],
    // hobbies: communityFilters?.selectedHobbies || [],
    // roleType: communityFilters?.selectedRoleType || []
    //   })
    // );
    // handleApply();
    // fetchCommunityMembers();
    // fetchCommunityMembers(selectedRole, selectedUserType, selectedHobbies, selectedIndustry, selectedRoleType);
  }, []);

  useEffect(() => {
    setSelectedRole(filters?.selectedRole || []);
    setSelectedUserType(filters?.selectedUserType || []);
    setselectedIndustry(filters?.selectedIndustry || []);
    setselectedRoleType(filters?.selectedSkillType || []);
    setselectedHobbies(filters?.selectedHobbies || []);
    setSelectedBsIndustries(filters?.selectedBsIndustries || []);
  }, []);
  useEffect(() => {
    setSelectedMember(state?.mem);
  }, [state]);

  const firstRender = useRef(true);
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    fetchCommunityMembers(
      selectedRole,
      selectedUserType,
      selectedHobbies,
      selectedIndustry,
      selectedRoleType,
      selectedBsIndustries,
      selectedCountries
    );
  }, [applyFilter]);

  useEffect(() => {
    if (allCommunityMembersList?.length === communityMembers?.data?.length) {
      setAllCommunityMembersList(
        communityMembers?.data?.filter((each: any) => {
          if ('isPlatformAdmin' in each) {
            return false; // Exclude platform admins
          }

          if (isAgileExec && each.category && !AGILE_ALLOWED_CATEGORY_COMMUNITY.includes(each.category)) {
            return false; // Exclude members not in the allowed Agile categories
          }

          return true; // Include members that pass the conditions
        }) || []
      );
    }
  }, [reset, communityMembers]);

  useEffect(() => {
    setAllCommunityMembersList(
      state?.allCommunityMembersList?.filter((each: any) => {
        if ('isPlatformAdmin' in each) {
          return false; // Exclude platform admins
        }

        if (isAgileExec && each.category && !AGILE_ALLOWED_CATEGORY_COMMUNITY.includes(each.category)) {
          return false; // Exclude members not in the allowed Agile categories
        }

        return true; // Include members that pass the conditions
      }) || []
    );
  }, [state?.allCommunityMembersList]);

  useEffect(() => {
    if (selectedMember?.userId) {
      dispatch(fetchUserWorkHistory(selectedMember?.id));
      dispatch(fetchUserEducation(selectedMember?.id));
    }
  }, [selectedMember]);

  useEffect(() => {
    refrence?.current && refrence?.current?.scrollIntoView();
  }, [refrence?.current]);

  useEffect(() => {
    const newUserId = _.get(selectedMember, 'id', '');
    if (newUserId) {
      dispatch(userIdUpdate(newUserId));
    }
  }, [selectedMember]);

  useEffect(() => {
    data.refetch();
  }, [selectedUserId]);

  useEffect(() => {
    // This function will be called when the component unmounts
    return () => {
      handleReset();
    };
  }, []);

  const onClickOnUserIcon = (mem: any) => {
    setSelectedMember(mem);
  };

  const handleUserTypeChnage = (userType: string) => {
    setSelectedUserType((prevUserType) =>
      prevUserType?.includes(userType)
        ? prevUserType?.filter((type) => type !== userType)
        : [...(prevUserType || []), userType]
    );
  };

  const handleIndustryChange = (industry: string) => {
    setselectedIndustry((prevInd) =>
      prevInd?.includes(industry) ? prevInd?.filter((ind) => ind !== industry) : [...(prevInd || []), industry]
    );
  };

  const handleHobbiesChange = (hobbies: string) => {
    setselectedHobbies((prevHobbies) =>
      prevHobbies?.includes(hobbies) ? prevHobbies?.filter((hob) => hob !== hobbies) : [...(prevHobbies || []), hobbies]
    );
  };

  const handleRoleTypeChange = (roleType: string) => {
    const index = topics.findIndex((each) => each.label === roleType);
    const name = index !== -1 ? topics[index].name : '';
    setselectedRoleType((prevTypes) =>
      prevTypes.includes(name) ? prevTypes.filter((t) => t !== name) : [...prevTypes, name]
    );
  };

  const handleApply = () => {
    setApplyFilter(!applyFilter);
    dispatch(
      communityFilterAction.updateCommunityFilter({
        selectedRole: selectedRole,
        selectedUserType: selectedUserType,
        selectedIndustry: selectedIndustry,
        selectedRoleType: selectedRoleType,
        selectedHobbies: selectedHobbies,
        selectedBsIndustries: selectedBsIndustries,
        selectedCountries
      })
    );
    handleCloseFilter();
  };

  const handleReset = () => {
    setReset(true);
    setSelectedRole([]);
    setSelectedUserType([]);
    setselectedIndustry([]);
    setselectedRoleType([]);
    setselectedHobbies([]);
    setSelectedBsIndustries([]);
    setSelectedCountries([]);
    dispatch(fetchCommunityMember({ orgId, location }));
    dispatch(
      communityFilterAction.updateCommunityFilter({
        selectedRole: [],
        selectedUserType: [],
        selectedIndustry: [],
        selectedRoleType: [],
        selectedHobbies: [],
        selectedCountries: []
      })
    );
    const list =
      communityMembers?.data
        ?.filter((each: any) => {
          if ('isPlatformAdmin' in each) {
            return false; // Exclude platform admins
          }

          if (isAgileExec && each.category && !AGILE_ALLOWED_CATEGORY_COMMUNITY.includes(each.category)) {
            return false; // Exclude members not in the allowed Agile categories
          }

          return true; // Include members that pass the conditions
        })
        .sort((a, b) =>
          _.get(a, 'displayName', ' ')
            .trim()
            .toLowerCase()
            .localeCompare(_.get(b, 'displayName', ' ').trim().toLowerCase())
        ) || [];
    setAllCommunityMembersList(list);
    setSelectedMember(list[0]);
    setOpenFilter(false);
    setSearchCountry('');
    setCountryList(countries);
  };

  // useEffect(() => {
  //   setAllCommunityMembersList(communityMembers?.data?.filter((each: any) => !('isPlatformAdmin' in each)) || []);
  // }, [communityMembers]);

  const handleClickOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
    setSearchCountry('');
    setCountryList(countries);
  };

  const handleCloseBookTime = () => {
    setOpenBookTime(false);
  };

  const fetchTagList = async (type: string) => {
    try {
      const responseFYE = await API.getTagsListnew(orgId, location, type, '', true);
      if (responseFYE.status === 200) {
        return responseFYE?.data?.tagListResponse?.map((each: any) => each.value) || [];
      } else {
        return [];
      }
    } catch {
      return [];
    }
  };

  const fetchCommunityMembers = async (
    roleSelected: string[],
    category: string[],
    hobbiesAndInterest: string[],
    industry: string[],
    skillType: string[],
    bsIndustries: string[],
    countries: string[]
  ) => {
    // try {
    setLoading(true);
    // const response: any = await API.getCommunityGroupsUsersListNew(
    //   orgId,
    //   location,
    //   role,
    //   category,
    //   industry,
    //   hobbiesAndInterest,
    //   roleType
    // );
    const filteredResponse =
      communityMembers?.data &&
        (roleSelected?.length ||
          hobbiesAndInterest?.length ||
          industry?.length ||
          skillType?.length ||
          bsIndustries?.length ||
          countries?.length ||
          category?.length)
        ? communityMembers?.data.filter((member: any) => {
          // const roleMatch = member?.userType?.some((each: any) => roleSelected?.map(s => s.toLowerCase().includes(each.toLowerCase())))
          const roleMatch =
            roleSelected?.length > 0
              ? member?.userType?.length === 0 && roleSelected.includes('Admin')
                ? true
                : member?.userType?.find((each: any) =>
                  roleSelected?.includes(each.charAt(0).toUpperCase() + each.slice(1))
                )
              : true;

          const hobbiesMatch =
            hobbiesAndInterest?.length > 0
              ? member?.hobbies?.some((hobby: any) =>
                hobbiesAndInterest?.map((s) => s.toLowerCase()).includes(hobby.toLowerCase())
              )
              : true;
          // const industryMatch =
          //   industry?.length > 0
          //     ? member?.industries?.some((ind: any) =>
          //         industry?.map((s) => s.toLowerCase()).includes(ind.toLowerCase())
          //       )
          //     : true;
          const convertedIndustry = capitalToCamelCaseIndustries(industry);
          const industryMatch =
            industry?.length > 0
              ? member?.savedIndustries?.some((ind: any) =>
                convertedIndustry?.map((s) => s.toLowerCase()).includes(ind.toLowerCase())
              )
              : true;
          const skillsMatched =
            skillType?.length > 0
              ? member?.savedTopics?.some((skill: any) =>
                skillType?.map((s) => s.toLowerCase()).includes(skill.toLowerCase())
              )
              : true;
          const bsIndustriesMatched =
            bsIndustries?.length > 0
              ? member?.savedIndustries?.some((bsInd: any) =>
                bsIndustries?.map((s) => s.toLowerCase()).includes(bsInd.toLowerCase())
              )
              : true;
          const countryMatch = countries?.length > 0 ? countries?.includes(member?.company?.location || '') : true;
          const categoryMatch = category?.length > 0 ? category?.includes(member?.category || '') : true;
          return (
            roleMatch &&
            hobbiesMatch &&
            industryMatch &&
            skillsMatched &&
            bsIndustriesMatched &&
            countryMatch &&
            categoryMatch
          );
          // return roleMatch
        })
        : communityMembers?.data;

    // if (response?.status === 200 ) {
    //   if (Array.isArray(response?.data.memberResponse)) {
    //     setAllCommunityMembersList(
    //       response?.data?.memberResponse?.filter((each: any) => {
    //         if ('isPlatformAdmin' in each) {
    //           return false; // Exclude platform admins
    //         }

    //         if (isAgileExec && each.category && !AGILE_ALLOWED_CATEGORY_COMMUNITY.includes(each.category)) {
    //           return false; // Exclude members not in the allowed Agile categories
    //         }

    //         return true; // Include members that pass the conditions
    //       }) || []
    //     );
    const list = filteredResponse
      ?.filter((each: any) => {
        if ('isPlatformAdmin' in each) {
          return false; // Exclude platform admins
        }

        if (isAgileExec && each.category && !AGILE_ALLOWED_CATEGORY_COMMUNITY.includes(each.category)) {
          return false; // Exclude members not in the allowed Agile categories
        }

        return true; // Include members that pass the conditions
      })
      .sort((a, b) =>
        _.get(a, 'displayName', ' ')
          .trim()
          .toLowerCase()
          .localeCompare(_.get(b, 'displayName', ' ').trim().toLowerCase())
      );
    setSelectedMember(list ? list[0] : undefined);

    setAllCommunityMembersList(list || []);

    // } else {
    //   setAllCommunityMembersList([]);
    // }
    setLoading(false);
    // }
    // } catch (error) {
    //   setLoading(false);
    //   toast.error('Failed while loading community list');
    // }}
  };

  return (
    <>
      <Box className="containerBox">
        {!forMobile ? (
          <Box className="containerBoxLeft">
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} p={2}>
              <Typography variant="h5">Network</Typography>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                <Typography>{`All (${formatNumberWithCommas(allCommunityMembersList?.length ?? 0)})`}</Typography>
                <Box>
                  <FilterIcon size={34} onClick={handleClickOpenFilter} />
                </Box>
              </Stack>
            </Stack>

            <Box className="MemberTabs">
              <Typography className="divText active">All Members</Typography>
              {/* <Typography className="divText">Favorites</Typography> */}
            </Box>

            <Box className="User-list">
              {communityMembers.loading || loading ? <CommunityLoaderLeft /> : ''}
              {_.isArray(allCommunityMembersList) &&
                allCommunityMembersList?.length > 0 &&
                [...allCommunityMembersList]
                  ?.sort((a, b) =>
                    _.get(a, 'displayName', ' ')
                      .trim()
                      .toLowerCase()
                      .localeCompare(_.get(b, 'displayName', ' ').trim().toLowerCase())
                  )
                  ?.map((mem, index) => {
                    return (
                      <React.Fragment key={index}>
                        <CommunitySidebar
                          mem={mem}
                          selectedMember={selectedMember}
                          onClickOnUserIcon={onClickOnUserIcon}
                          // loading={loading} // Skeleton loader
                          // setMember={setMemberSelected}
                          openFilter={openFilter}
                        />
                      </React.Fragment>
                    );
                  })}
              {!loading &&
                !communityMembers.loading &&
                _.isArray(allCommunityMembersList) &&
                allCommunityMembersList?.length === 0 && (
                  <Typography p={2} textAlign="center">
                    No members found
                  </Typography>
                )}
            </Box>
          </Box>
        ) : null}

        <Box className="containerBoxRight">
          {/* For Mobile -> details to back button */}
          {mobileBackBtn('back')}

          {!(loading || communityMembers?.loading) &&
            _.isArray(allCommunityMembersList) &&
            allCommunityMembersList?.length > 0 &&
            userDetails && (
              <ChatProfile
                workHistory={userWorkHistory}
                educationDetails={userEducation}
                chatProfile={userDetails}
                onCloseChatProfile={() => { }}
                setActiveChat={() => { }}
                parentComponent="CommunityMember"
                digitalIntro={true}
              />
            )}
          {(loading || communityMembers?.loading || data?.isLoading) && <CommunityLoaderRight />}
          {/* <SimilarProfiles /> */}
        </Box>
      </Box>

      {/* Filter popup */}
      <Dialog open={openFilter}>
        {/* Dialog Header */}
        <CommonModalHeader title="Filter" handleCloseModal={handleCloseFilter} />
        <DialogContent>
          {communityMembers.loading || loading ? (
            <FilterLoader />
          ) : (
            <>
              {/* Role */}
              <>
                {/* <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="Role-content"
                    id="Role-header"
                    className="px-0 
                    filterAccordionSummary"
                  >
                    <Typography variant="h5">Role</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="px-0 pb-0">
                    {roles.map((role, index) => (
                      <FilterCheckField
                        key={index}
                        label={role}
                        onChange={handleRoleChange}
                        checked={selectedRole.includes(role)}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion> */}
                {/* <Divider /> */}
              </>
              {/* Skills */}
              {roleTypeList?.length > 0 && (
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="Skills-content"
                      id="Skills-header"
                      className="px-0 filterAccordionSummary"
                    >
                      <Typography variant="h5">Expertise</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="px-0 pb-0">
                      {/* Search */}
                      <Box pb={1}>
                        <FilterSearch
                          userTypeDate={{ placeholderText: 'Search Skills' }}
                          value={searchSkillTerm}
                          onChange={handleSearchSkills}
                        />
                      </Box>
                      {/* Skills opction */}
                      <Box>
                        {uniqueRoleTypes.length > 0 ? (
                          uniqueRoleTypes.map((roleType, index) => (
                            <FilterCheckField
                              key={index}
                              label={roleType.label}
                              onChange={handleRoleTypeChange}
                              checked={selectedRoleType?.includes(roleType.name)}
                            />
                          ))
                        ) : (
                          <Typography textAlign="center" pt={1}>
                            No data found
                          </Typography>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </>
              )}
              {/* Industry */}
              {industryList?.length > 0 && (
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="Industry-content"
                      id="Industry-header"
                      className="px-0 filterAccordionSummary"
                    >
                      <Typography variant="h5">Industries</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="px-0 pb-0">
                      {industryList
                        ?.slice()
                        ?.sort()
                        ?.map((industry, index) => {
                          return (
                            <FilterCheckField
                              key={index}
                              label={industry}
                              onChange={handleIndustryChange}
                              checked={selectedIndustry?.includes(industry)}
                            />
                          );
                        })}
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </>
              )}
              {/* Customer Category */}

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="Customer-content"
                  id="Customer-header"
                  className="px-0 filterAccordionSummary"
                >
                  <Typography variant="h5">Member Type</Typography>
                </AccordionSummary>
                <AccordionDetails className="px-0 pb-0">
                  {customerCategory.map((customerCategory, index) => (
                    <FilterCheckField
                      key={index}
                      label={customerCategory}
                      onChange={handleUserTypeChnage}
                      checked={selectedUserType?.includes(customerCategory)}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
              <Divider />
              {(showCompany || isPlatformAdmin || isOrgAdmin) && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="Skills-content"
                    id="Skills-header"
                    className="px-0 filterAccordionSummary"
                  >
                    <Typography variant="h5">Company Location</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="px-0 pb-0">
                    {/* Search */}
                    <Box pb={1}>
                      <FilterSearch
                        userTypeDate={{ placeholderText: 'Search Company Location' }}
                        value={searchCounrty}
                        onChange={handleSearchCountry}
                      />
                    </Box>
                    {/* Skills opction */}
                    <Box>
                      {countryList.map((tags, index) => (
                        <FilterCheckField
                          key={index}
                          label={tags.label}
                          onChange={handleCountryChange}
                          checked={selectedCountries.includes(tags.label)}
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}

              {/* Hobbies & Interests */}
              {/* {categoryUserType === 'Higher Education' && hobbiesList?.length > 0 && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="Hobbies-content"
                    id="Hobbies-header"
                    className="px-0 filterAccordionSummary"
                  >
                    <Typography variant="h5">Hobbies & Interests</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="px-0 pb-0">
                    {hobbiesList?.map((hobbies, index) => {
                      return (
                        <FilterCheckField
                          key={index}
                          label={hobbies}
                          onChange={handleHobbiesChange}
                          checked={selectedHobbies?.includes(hobbies)}
                        />
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              )} */}
              {/* bs industries */}
              {/* {bsIndustriesList?.length > 0 && (
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="Industries-content"
                      id="Industries-header"
                      className="px-0 filterAccordionSummary"
                    >
                      <Typography variant="h5">Industries</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="px-0 pb-0">
                      <Box>
                        {filteredBsIndustriesList.length > 0 ? (
                          filteredBsIndustriesList.map((bsIndustries, index) => (
                            <FilterCheckField
                              key={index}
                              label={bsIndustries.label}
                              onChange={handleBsIndustriesChange}
                              checked={selectedBsIndustries?.includes(bsIndustries.name)}
                            />
                          ))
                        ) : (
                          <Typography textAlign="center" pt={1}>
                            No data found
                          </Typography>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </>
              )} */}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" fullWidth onClick={handleReset}>
            Reset
          </Button>
          <Button variant="contained" fullWidth onClick={handleApply}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      {/* Use Google's location service */}
      <Dialog open={openBookTime} onClose={handleCloseBookTime} fullWidth>
        <CommonModalHeader
          // handleCloseModal={}
          title={"Use Google's location service?"}
        />
        <DialogContent>
          <Typography variant="h3">
            Let Google help apps determine location. This means sending anonymous location data to Google, even when no
            apps are running.3
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBookTime}>Disagree</Button>
          <Button onClick={handleCloseBookTime} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CommunityMember;
