import { styled } from '@mui/system';
import { Box, CardMedia } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const MyCardBox1 = styled(Box)({
  borderRadius: 8,
  border: '1px solid #EFF0F4',
  background: '#FFFFFF',
  padding: 20,
  '@media (min-width: 600px)': {
    minHeight: '240px',
  },
  height: '100%'
});

export const MyCardMedia = styled(CardMedia)({
  padding: 0,
  margin: 0,
  width: 14,
  height: 13
});

export const CardStyle = makeStyles(() => ({
  cardBox: {
    borderRadius: 8,
    border: '1px solid #EFF0F4',
    background: '#FFFFFF',
    // minHeight: 240,
    height: '100%'
  },
  
  mainBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  
  flexColumn: {
    display: 'flex',
    flexDirection:'column'
  }
}));
