import React, { useState } from 'react';
import { Box, Tooltip, Divider, Typography, Link } from '@mui/material';
import resumeimg from '../../../../../assets/images/resumeimg.svg';
import { ArticleLoader } from '../../home/TasksPageLoader';
// import CheckableTickIcon from '../../../../../components/CheckableTickIcon';

interface Article {
  id: string;
  coverImageUrl: string;
  articleName: string;
  readTime: string;
  author: string;
  location: string;
}

const dummyData: Article[] = [
  {
    id: '1',
    coverImageUrl: resumeimg,
    articleName: 'How to target industries',
    readTime: '5 min',
    author: 'Ronan Wall',
    location: 'default',
  },
  {
    id: '2',
    coverImageUrl: resumeimg,
    articleName: 'Overview of Top US Industries in 2022',
    readTime: '8 min',
    author: 'Jane Smith',
    location: 'default',
  },
  {
    id: '3',
    coverImageUrl: resumeimg,
    articleName: 'Building Your Career Path',
    readTime: '10 min',
    author: 'Chris Doe',
    location: 'default',
  },
];

interface RecommendedArticleProps {
  direction?: 'row' | 'column';
  place?: 'library' | 'menteeTask' | 'help' | 'mentorHome';
  data?: Article[];
  isLoading?: boolean;
  handleArticleClick?: (id: string, location: string) => void;
}

const RecommendedArticle: React.FC<RecommendedArticleProps> = ({
  direction = 'column',
  place = 'library',
  data = [],
  isLoading,
  handleArticleClick = () => { },
}) => {
  const articles = data // dummyData;

  const getTitle = (): string => {
    switch (place) {
      case 'library':
        return 'Recommended Content';
      case 'menteeTask':
        return 'Lesson Materials';
      default:
        return 'Recommended Content';
    }
  };

  const getPlaceholderText = () => {
    switch (place) {
      case 'mentorHome':
        return 'No curated articles available.';
      case 'menteeTask':
        return (
          <>
            No articles recommended for you yet.{' '}
            <Link href="/#/app/library" color="#0071A9" variant="subtitle2">
              Click here
            </Link>{' '}
            to see all the articles available.
          </>
        );
      default:
        return 'No similar data found.';
    }
  };

  const containerWidth = direction === 'row' ? '292px' : '100%';
  const boxWidth = `calc(${containerWidth} - 99px)`;

  const cursorClass = typeof handleArticleClick === 'function' && handleArticleClick.toString() !== '() => {}' ? 'cursor-pointer' : '';

  // State to track checked status of articles
  const [checkedArticles, setCheckedArticles] = useState<Record<string, boolean>>(
    () => Object.fromEntries(articles.map((article) => [article.id, false])) // Initial state
  );

  const handleCheckChange = (id: string, newState: boolean) => {
    setCheckedArticles((prevState) => ({
      ...prevState,
      [id]: newState,
    }));
  };

  return isLoading ? (
    <ArticleLoader />
  ) : (
    <Box className="CardBox">
      <Box p={2}>
        <Typography variant="h5">{getTitle()}</Typography>
      </Box>
      <Divider />
      <Box p={2} width="100%" overflow={direction === 'row' ? 'auto' : 'visible'}>
        {articles.length > 0 ? (
          <Box
            display="flex"
            gap="20px"
            flexDirection={direction === 'row' ? 'row' : 'column'}
            flexWrap={direction === 'row' ? 'nowrap' : 'wrap'}
          >
            {articles.map((article) => (
              <Box
                key={article.id}
                display="flex"
                flexDirection="row"
                gap="20px"
                width={containerWidth}
                className={cursorClass}
                onClick={() => handleArticleClick(article.id, article.location)}
                aria-label={`Open article: ${article.articleName}`}
              >
                <Box
                  component="img"
                  src={article.coverImageUrl}
                  alt={article.articleName}
                  width="118px"
                  height="79px"
                  minWidth={'118px'}
                  borderRadius={'5px'}
                  sx={{ objectFit: 'cover' }}
                />
                <Box width={boxWidth}>
                  {/* Tick icon */}
                  {/* {place === 'menteeTask' && (
                    <CheckableTickIcon
                      checked={checkedArticles[article.id]}
                      onChange={(newState) => handleCheckChange(article.id, newState)}
                    />
                  )} */}
                  <Tooltip title={article.articleName}>
                    <Typography className="onlyShowTwoLine" variant="h5">
                      {article.articleName}
                    </Typography>
                  </Tooltip>
                  <Typography>{article.readTime}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Typography textAlign="center">{getPlaceholderText()}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default RecommendedArticle;
