/* eslint-disable max-nested-callbacks */
/* eslint-disable spaced-comment */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-key */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  // Grid,
  Link,
  Radio,
  Rating,
  Tooltip,
  Popover,
  Divider,
  TextField,
  RadioGroup,
  Typography,
  FormControl,
  Autocomplete,
  FormControlLabel,
} from '@mui/material';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import '../../../../assets/style/sass/menteeTask.scss';
import personmentor from '../../../../assets/images/personmentor.svg';
import greencheckimg from '../../../../assets/images/greencheckimg.svg';
import lightcheckimg from '../../../../assets/images/lightcheckimg.svg';
import greycheckimg from '../../../../assets/images/greycheckimg.svg';
import GradeIcon from '@mui/icons-material/Grade';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { fetchAllTasks, taskActions } from '../../../../redux/slices/goals-tasks/getTasksSlice';
import { navigateToChat } from '../NavigateToChat';
import { API } from '../../../../api';
import { useAuth0 } from '@auth0/auth0-react';
import CallBookingModal from '../CallBookingModal';
import { toast } from 'react-toastify';
import { Error } from '@mui/icons-material';
// import ChatWithMeDialog from '../../tabs/chat/ChatWithMeDialog';
// import useAllGroupTasks from '../../../../queries/useAllGroupTasks';
import RecommendedArticle from '../Library/components/RecommendedArticle';
import { userIdUpdate } from '../../../../redux/slices/userId/userIdSlice';
import useUserDetailsById from '../../../../queries/useUserDetailsById';
import { MentorLoader, TasksLoader, TasksQuesLoader, ArticleLoader } from './TasksPageLoader';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
//import ExperiencePoints from './cards/ExperiencePoints';
import MyMentorCard from './MyMentorCard';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { menteeTaskSlicAction } from '../../../../redux/slices/user-task/userMenteeTask';
import MenteeTaskPopup from './MenteeTaskPopup';
import { getTrophyBadge, GroupBasedRoleFormatter, lockIcon, topicArray, industryArray } from '../../components/common';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { DeleteIcon, EditIcon } from '../../../../utils/Icons';
import MySkeleton from '../../../../components/Skeleton';
import { useQueryClient } from '@tanstack/react-query';
import useAllGroupTasks from '../../../../queries/useAllGroupTasks';
import useReCArticles from '../../../../queries/useRecArticles';
import { getUserDetails } from '../../../../utils/orgName';
import useRecMentors from '../../../../queries/useRecMentors';
import { queryKeys } from '../../../../queries/queryKeys';
import { Article } from '../Program/components/program tabs/ContentSettings';
import PreviewContent from '../Program/components/PreviewContent';
import { saveAnswerActions } from '../../../../redux/slices/saveAnswersSlice';
import { checkOrfium, getOrfiumLevelName } from '../../../../utils/orfiumFunctions';
import DescriptionModal from '../../components/DescriptionModal';
import { levelTypes } from '../../../../utils/constants';
import RenderHTML from '../../../../components/RenderHTML';

let source = axios.CancelToken.source();
interface TaskData {
  data: any;
}

const navigateTaskQuestionsToTop = (side: 'left' | 'right') => {
  if (side === 'left') {
    const [leftContainer] = window.document.getElementsByClassName('containerBoxLeft');
    if (leftContainer) {
      leftContainer.scrollTo(0, 0);
    }
  } else {
    const [rightContainer] = window.document.getElementsByClassName('containerBoxRight');
    if (rightContainer) {
      rightContainer.scrollTo(0, 0);
    }
  }
};

const GetTaskQuestionOptions = ({
  personType,
  disablePointers,
  type,
  answerGiven,
  ansOptions,
  handleClick,
  maxMenteeAns,
  minMenteeAns,
  answers,
  selectedDropdownValues,
  handleInputValueChange,
  inputValue,
  setInputValue,
  handleBlurCapture,
  isMenteeAnsGiven,
  quesId,
  menteeQuestions
}: any) => {
  const topics = topicArray();
  const industries = industryArray();

  const lowercaseFirstLetter = (options: any) => {
    if (!Array.isArray(options)) return [];
    return options.map(option => option.charAt(0).toLowerCase() + option.slice(1));
  };
  const camelCaseToCapitalCombined = (ansOptions: string[]) => {
    return ansOptions.map((tag: string) => {
      let index;
      index = topics.findIndex((each: any) => each.name === tag);
      if (index !== -1) {
        return topics[index].label;
      }

      index = industries.findIndex((each: any) => each.name === tag);
      if (index !== -1) {
        return industries[index].label;
      }
      return tag;
    });
  };

  const transformedOptions = lowercaseFirstLetter(ansOptions)
  const checkAnswers = (e: any) => {
    let count = 0;
    if (e.target.value === 'Yes') {
      menteeQuestions?.forEach((each: any) => {
        if (each?.menteeAns?.length === 0) {
          count++;
        }
      });
      if (count > 0) {
        toast.warning('Please answer the question before marking complete');
        return;
      }
    }
    handleClick(personType, e.target.value, type, quesId);
  };
  if (personType === 0) {
    switch (type) {
      case 'rating': {
        return (
          <Rating
            value={
              _.findIndex(ansOptions, (a) =>
                _.parseInt(String(a)) === _.parseInt(String(_.get(answerGiven, [0], '')))
              ) + 1
            }
            onChange={(event, newValue) => {
              if (!disablePointers && newValue !== null) {
                const selectedOption = ansOptions[newValue - 1];
                handleClick(personType, String(selectedOption), type, quesId);
              }
            }}
            emptyIcon={<GradeIcon className={disablePointers ? 'disabled-color' : 'secondary-light'} />}
            icon={<GradeIcon className="color-golden" />}
            readOnly={disablePointers}
          />
        );
      }
      case 'radio':
        return (
          <>
            <FormControl className={`MenteetasksFieldSize`}>
              <RadioGroup
                className={`gap-10 ${disablePointers ? 'opacity-50' : ''}`}
                onChange={(e) => handleClick(personType, e.target.value, type, quesId)}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
              >
                {_.map(ansOptions, (each) => (
                  <Box className="industries-sec">
                    <FormControlLabel
                      value={each}
                      checked={answerGiven?.includes(each)}
                      control={<Radio disabled={disablePointers} />}
                      label={each}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          </>
        );
      case 'input':
        return (
          <FormControl fullWidth>
            <TextField
              fullWidth
              className={disablePointers ? 'disabled-bg' : ''}
              disabled={disablePointers}
              onChange={
                (e: any) => handleClick(personType, e.target.value, type, quesId)
                // setInputValue({ quesId: quesId, value: e.target.value })
              }
              // variant="outlined"
              multiline
              maxRows={4}
              onBlurCapture={() => {
                handleBlurCapture();
              }}
              placeholder="Please input your answer..."
              value={inputValue?.value?.length ? inputValue?.value : answerGiven}
            />
          </FormControl>
        );
      case 'multiselect':
        return (
          <Box className="">
            <Autocomplete
              className="AutocompleteMenteetasksFieldSize"
              disableClearable
              multiple
              disabled={disablePointers}
              options={disablePointers ? [] : _.sortBy(camelCaseToCapitalCombined(transformedOptions))}
              getOptionLabel={(option) => option}
              value={selectedDropdownValues?.values?.length ? selectedDropdownValues?.values : answerGiven}
              onChange={(e: any, value: any) => {
                handleClick(personType, value, 'multiselect', quesId);
              }}
              disableCloseOnSelect
              filterOptions={(options, state) =>
                options.filter((option) => option.toLowerCase().startsWith(state.inputValue.toLowerCase()))
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder={`Select Minimum 1 options`} />
              )}
            />
          </Box>
        );
      case 'Yes, No':
        return (
          <>
            <FormControl className="MenteetasksFieldSize">
              <RadioGroup
                className={`gap-10 ${disablePointers ? 'opacity-50' : ''}`}
                onChange={(e) => handleClick(personType, e.target.value, type, quesId)}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
              >
                {_.map(ansOptions, (each) => (
                  <Box className="industries-sec">
                    <FormControlLabel
                      value={each}
                      checked={answerGiven?.includes(each)}
                      control={<Radio disabled={disablePointers} />}
                      label={each}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          </>
        );
      case "Yes, No, I don't know":
        return (
          <>
            <FormControl className="MenteetasksFieldSize">
              <RadioGroup
                className={`gap-10 ${disablePointers ? 'opacity-50' : ''}`}
                onChange={(e) => handleClick(personType, e.target.value, type, quesId)}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
              >
                {_.map(ansOptions, (each) => (
                  <Box className="industries-sec">
                    <FormControlLabel
                      value={each}
                      checked={answerGiven?.includes(each)}
                      control={<Radio disabled={disablePointers} />}
                      label={each}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          </>
        );

      default:
        return null;
    }
  } else if (personType === 1) {
    switch (type) {
      case 'Yes, No':
        return (
          <>
            <FormControl className="MenteetasksFieldSize">
              <RadioGroup
                className={`gap-10 ${disablePointers ? 'opacity-50' : ''}`}
                // onChange={(e) => handleClick(personType, e.target.value, type, quesId)}
                onChange={(e) => checkAnswers(e)}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
              >
                {_.map(ansOptions, (each) => (
                  <Box className="industries-sec">
                    <FormControlLabel
                      defaultValue="No"
                      value={each}
                      checked={answerGiven?.includes(each)}
                      control={<Radio disabled={!isMenteeAnsGiven || disablePointers} />}
                      label={each}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          </>
        );
      case "Yes, No, I don't know":
        return (
          <>
            <FormControl className="MenteetasksFieldSize">
              <RadioGroup
                className={`gap-10 ${disablePointers ? 'opacity-50' : ''}`}
                onChange={(e) => handleClick(personType, e.target.value, type, quesId)}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="No"
                name="radio-buttons-group"
              >
                {_.map(ansOptions, (each) => (
                  <Box className="industries-sec">
                    <FormControlLabel
                      value={each}
                      checked={answerGiven?.includes(each)}
                      control={<Radio disabled={!isMenteeAnsGiven || disablePointers} />}
                      label={each}
                    />
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          </>
        );
      default:
        return (
          <FormControl className="MenteetasksFieldSize">
            <RadioGroup
              className={`gap-10 ${disablePointers ? 'opacity-50' : ''}`}
              onChange={(e) => handleClick(personType, e.target.value, type, quesId)}
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="No"
              name="radio-buttons-group"
            >
              {_.map(ansOptions, (each) => (
                <Box className="industries-sec">
                  <FormControlLabel
                    value={each}
                    checked={answerGiven?.includes(each)}
                    control={<Radio disabled={!isMenteeAnsGiven || disablePointers} />}
                    label={each}
                  />
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
        );
    }
  } else {
    return null;
  }
};

const updateHTMLWithCorrectLink = (t: any) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(t, 'text/html');

  const link = doc.querySelector('a[href="app.joinhandshake.com"]');
  if (link) {
    let href: any = link.getAttribute('href');
    if (!href.startsWith('http') && !href.startsWith('//')) {
      href = 'https://' + href;
      link.setAttribute('href', href);
    }
  }
  if (link) {
    if (!link.hasAttribute('target')) {
      link.setAttribute('target', '_blank');
    }
  }

  return doc.body.innerHTML;
};

const MenteeTask = () => {
  // const groupAllGroupTasksDetails = useAllGroupTasks();
  const goalTasks = useAppSelector((state) => state.goalTasks);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userName = decodeURIComponent(queryParams.get('userName') || '');
  const queryClient = useQueryClient();
  const { user } = useAuth0();
  const taskUserId = useAppSelector((state) => state.goalTasks.userId);

  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  // const allTaskData = useAppSelector((state) => state.goalTasks.data?.data[0]?.levels);
  const { refetch, isLoading, isFetching, data: userTaskData } = useAllGroupTasks(selectedGroupId.id, taskUserId);
  const allTaskData = userTaskData?.data.data[0]?.levels;
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({ quesId: '', values: [] });
  const menteeTask = useAppSelector((state) => state?.goalTasks);
  const communityMembers = useAppSelector((state) => state.acsCommunityUsers.data);
  // const communityMembers = useAppSelector((state) => state.getCommunityMembers.data);
  const commList = useAppSelector((state) => state.getCommunityMembers.data);
  const { taskId } = useParams() || '';
  const { levelId } = useParams() || '';
  const { coId, location: userLoc, orgId } = getUserDetails();
  const {
    data: recArticles,
    refetch: fetchRecArticles,
    isLoading: articlesLoading,
    isFetching: articlesFetching
  } = useReCArticles({
    grpId: selectedGroupId.id,
    location: userLoc,
    orgId,
    userId: taskUserId,
    levelId: levelId || '',
    taskId: taskId || ''
  });
  const {
    data: recMentors,
    refetch: fetchRecMentors,
    isLoading: mentorsLoading,
    isFetching: mentorsFetching
  } = useRecMentors({
    grpId: selectedGroupId.id,
    location: userLoc,
    orgId,
    userId: taskUserId,
    levelId: levelId || '',
    taskId: taskId || ''
  });
  const myMentors = recMentors?.data.MyMentors;
  const personalThreadsArr = useAppSelector((state) => state.acsChannels.personalThreadsArr);
  const [quesLoading, setQuesLoding] = useState<Boolean>(false); // false
  const [quesError, setQuesError] = useState<Boolean>(false);
  const [getTask, setGetTask] = useState<TaskData>({ data: null });
  const taskLoading = isLoading; // menteeTask.loading
  const taskData = menteeTask.data?.data;
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);
  const taskError = menteeTask.error;
  const [selectProfileRow, setSelectProfileRow] = useState<any>(null);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [myMentorsList, setMyMentorsList] = useState<any>();
  const [answers, setAnswers] = useState<any>();
  const [isAnswerSaved, setIsAnswerSaved] = useState(false);
  const [isMenteeAsmntAnswerSaved, setIsMenteeAsmtAnswerSaved] = useState(false);
  const [menteeAsmtAnswers, setMenteeAsmtAnswer] = useState<any>();
  const [displayName, setDisplayName] = useState('');
  const [participantUser, setParticipantUser] = useState([]);
  const [openCalendarModal, setCalendarOpen] = useState(false);
  const userWorkHistory = useAppSelector((state) => state.userWorkHistory);
  const mentorRoleText = GroupBasedRoleFormatter('mentor', 'Mentor'); // Mentor
  const mentorRoleTextLowerCase =
    selectedGroupId.id === '64fa3b0ef3cbc20ba20de466' ? 'Matches' : mentorRoleText.toLowerCase(); // mentor
  const [saveAnswersLoading, setSaveAnswersLoading] = useState(false);
  const [reloadFlag, setReloadFlag] = useState(false);
  /** For responsive */
  const { mobileBackBtn } = useResponsiveScreenSwitch(); // Use the hook
  const { forMobile } = useResponsiveScreenSwitch();
  const userEducation = useAppSelector((state) => state.userEducation);
  const navigate = useNavigate();
  const groupId = selectedGroupId.id || '';
  const selectedUserRole = selectedGroupId?.role || '';
  const dispatch = useAppDispatch();
  //const xpPoints = menteeTask.data?.xpPoints;
  const selectedTaskRef = useRef<HTMLDivElement | null>(null);
  const isOrfium = checkOrfium(oid, selectedGroupId?.id || ''); // Orfium user check
  const getDetailTask = getTask?.data;
  // const isCurrentTask = getDetailTask?._id === taskId;
  const loginUserId = useAppSelector((state) => _.get(state.userProfile.data, '_id', ''));
  const groupDetails = useAppSelector((state) => state?.groupDetails);
  const bookedCallDetails: any = '';
  const getTaskById = async ({ groupId, levelId, taskId }: { groupId: string; levelId: string; taskId: string }) => {
    setSelectedDropdownValues({ quesId: '', values: [] });
    if (allTaskData?.length) {
      const level = allTaskData.find((each: any) => each._id === levelId);
      if (level) {
        const task = level.tasks.find((each: any) => each._id === taskId);
        // setInputValue(task.menteeAnswers?.length > 0 ? task.menteeAnswers[0] : '');
        setGetTask({ data: task });
      }
    }
  };

  const handleNavigateToChat = (member: any) => {
    const listedInCommunity = communityMembers?.find((each) => each?.userId === _.get(member, 'userId', ''));
    let isInCommunity = false;
    if (listedInCommunity) {
      isInCommunity = true;
    }
    navigateToChat(member?.communicationId, isInCommunity, personalThreadsArr, navigate, dispatch);
  };

  const data = useUserDetailsById();
  const userDetails = data?.data?.data?.data;
  const selectedUserId = useAppSelector((state) => state.userId.data);
  const selectedUserIdData = useAppSelector((state) => state.goalTasks.userId);

  /** Community member profile redirection */
  const moveToUsercommunityProfile = (userProfileRow: any) => {
    const mem = communityMembers?.find((each) => each?.userId === _.get(userProfileRow, 'userId', ''));

    if (mem) {
      const modifiedMem = { ...mem, id: _.get(mem, 'id.coId', '') };
      const allCommunityMembersList = communityMembers;
      let isInCommunity = true;
      navigate('/app/communitymember', {
        state: { mem: modifiedMem, isInCommunity, allCommunityMembersList: commList }
      });
    } else {
      console.log('Member not found');
    }
  };

  useEffect(() => {
    data.refetch();
  }, [selectedUserId]);

  useEffect(() => {
    if (selectProfileRow) {
      dispatch(userIdUpdate(selectProfileRow));
    }
  }, [selectProfileRow]);

  const handleCalendarOpen = (member: any) => {
    setDisplayName(member.displayName);
    setParticipantUser(member);
    setCalendarOpen(true);
  };

  const handleCalendarClose = () => {
    setDisplayName('');
    setParticipantUser([]);
    setCalendarOpen(false);
  };

  useEffect(() => {
    fetchRecArticles();
    fetchRecMentors();
  }, [selectedGroupId.id, selectedUserId, levelId, taskId]);

  const saveAnswers = async ({
    groupId,
    levelId,
    taskId,
    menteeAsmtAnswer,
    menteeAnswers
  }: {
    groupId: any;
    levelId: any;
    taskId: any;
    menteeAsmtAnswer: any;
    menteeAnswers: any;
  }) => {
    dispatch(saveAnswerActions.update(true));
    setSaveAnswersLoading(true);
    try {
      if (groupId && levelId && taskId) {
        // setGetTask((prev) => ({
        //   ...prev,
        //   data: { ...prev.data, menteeAnswers: menteeAnswers, menteeAsmtAns: menteeAsmtAnswer || '' }
        // }));

        const response = await API.saveAnswers({
          groupId,
          levelId,
          taskId,
          menteeAsmtAnswer: menteeAsmtAnswer || '',
          answers: Array.isArray(menteeAnswers) ? menteeAnswers.filter(Boolean) : [],
          cancelToken: source.token
        });
        if (response.status === 200) {
          setIsAnswerSaved(true);
          setIsMenteeAsmtAnswerSaved(true);
          //toast.success(response.data.message);
          executeInitialTaskData();
          setSelectedDropdownValues({ quesId: '', values: [] });
          // callGetTaskById();
        } else {
          toast.error(response.data);
        }
        // }
      }
    } catch (error) {
      dispatch(saveAnswerActions.update(false));
    } finally {
      setSaveAnswersLoading(false);
      dispatch(saveAnswerActions.update(false));
    }
  };

  const callGetTaskById = () => {
    if (groupId && levelId && taskId) {
      getTaskById({ groupId: groupId, levelId, taskId });
    }
  };
  useEffect(() => {
    if (!isLoading) {
      callGetTaskById();
    }
  }, [groupId, levelId, taskId, isLoading, allTaskData]);

  useEffect(() => {
    if (reloadFlag) {
      setEditModalOpen(false);
      setReloadFlag(false);
    }
    // dispatch(fetchAllTasks({ grpId: groupId, userId: '' }));
  }, [groupId, reloadFlag]);

  useEffect(() => {
    if (groupId && levelId && taskId) {
      if ((menteeAsmtAnswers || answers !== null) && !isAnswerSaved) {
        if (taskUserId === loginUserId) {
          // groupAllGroupTasksDetails.refetch();
          // saveAnswers({ groupId, levelId, taskId, menteeAsmtAnswer: menteeAsmtAnswers, menteeAnswers: answers });
        }
      }
    }
  }, [answers, menteeAsmtAnswers]);

  const executeInitialTaskData = () => {
    // if (groupId) {
    //   if (selectedGroupId.role === 'mentee') {
    //     dispatch(taskActions.setUserId({ userId: '' }));
    //     dispatch(fetchAllTasks({ userId: '', grpId: selectedGroupId.id }));
    //   } else if (selectedGroupId.role === 'mentor') {
    //     dispatch(taskActions.setUserId({ userId: selectedUserIdData }));
    //     dispatch(fetchAllTasks({ userId: selectedUserIdData, grpId: selectedGroupId.id }));
    //   }
    // }
  };

  // useEffect(() => {
  //   executeInitialTaskData();
  // }, [groupId, dispatch, reloadFlag]);

  const [myMentorsMatched, setMyMentorsMatches] = useState<any>();

  /** Chat with me Popup open */
  // const [openChatWithMe, setOpenChatWithMe] = useState(false);
  // const handleClickChatWithMe = (flag: boolean) => setOpenChatWithMe(flag);

  const handleArticleClick = (article_id: any, article_loc: any, articleType: any) => {
    if (articleType === 'helpCenter') {
      navigate('/app/help', { state: { navigatedArticleId: article_id, navigatedArticleLocation: article_loc } });
    } else {
      navigate('/app/library', { state: { navigatedArticleId: article_id, navigatedArticleLocation: article_loc } });
    }
  };

  const [articleData, setArticleData] = useState<Article>();
  const [openPreview, setOpenPreview] = useState(false);
  const [articleDataLoading, setArticleDataLoading] = useState(false);

  const handelOnClickPreviewArticleName = async (articleId: string, location: string) => {
    setArticleDataLoading(true);
    setOpenPreview(true);
    try {
      const response = await API.getAllArticleById({
        orgId: orgId,
        articleId: articleId,
        isContentSettings: false,
        articleLocation: location ? location : ''
      });

      if (response && response.status === 200) {
        setArticleData(response.data.article);
      }
    } catch (error) {
      toast.error(_.get(error, 'response.data.message', 'Something went wrong, please try again'));
      setOpenPreview(false);
      // console.error(error);
    } finally {
      setArticleDataLoading(false);
    }
  };

  const giveRating = (eachRating: any) => {
    if (!isAnswerSaved) {
      setAnswers([eachRating.toString()]);
    }
  };

  useEffect(() => {
    setAnswers([]); // Reset answers to an empty array when taskId changes
  }, [taskId]);

  const handleMultiSelect = (event: any, value: string[]) => {
    if (!isAnswerSaved) {
      setAnswers(value);
    }
  };

  const handleRadioChange = (event: any) => {
    if (!isAnswerSaved && event.target.value) {
      setAnswers([event.target.value]);
    }
  };

  const handleAsmtAnswers = (event: any) => {
    if (event.target.value) {
      setMenteeAsmtAnswer(event.target.value);
    }
  };

  const [selectedLevelId, setSelectedLevelId] = useState<any[]>([]);
  const [selectedLevelName, setSelectedLevelName] = useState('');
  const [rightTopPop, setRightTopPop] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState<any[]>([]);
  const rightTopDotsClick = (level: any) => (event: any) => {
    setSelectedLevelId(level._id);
    setSelectedLevelName(level.levelName);
    setEditModalOpen(true);
    setRightTopPop(event.currentTarget);
  };
  const handleClose = () => {
    setEditModalOpen(false);
    setRightTopPop(null);
  };
  const openMoreVert = Boolean(rightTopPop);

  // Goback to home page
  const goBack = () => {
    if (selectedGroupId.role === 'mentee') {
      navigate('/app/home/mentee'); // Navigate to mentee home page
    } else {
      navigate('/app/home/mentor'); // Navigate to mentor home page
    }
  };

  const rightTopPopover = openMoreVert ? 'simple-popover' : undefined;
  const [openDelete, setDelete] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const handleEditLevel = () => {
    dispatch(
      menteeTaskSlicAction.setSelectedLevel({
        selectedLevel: selectedLevelName,
        selectedLevelId: selectedLevelId
      })
    );
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: true, mode: 'edit' }));
  };

  const handleDeleteLevel = () => {
    dispatch(
      menteeTaskSlicAction.setSelectedLevel({
        selectedLevelId: selectedLevelId
      })
    );
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: true, mode: 'delete' }));
  };

  const handleAddTasks = (level: any) => {
    dispatch(menteeTaskSlicAction.setSelectedLevel({ selectedLevel: level.levelName, selectedLevelId: level._id }));
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: true, mode: 'addTask' }));
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [levelDescription, setLevelDescription] = useState('');
  const [levelNumber, setLevelNumber] = useState(0);
  const handleOpenDescription = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const currentDate = Date.now() / 1000; // Current date in Unix timestamp (seconds)

  function getAvailabilityMessage(startDate: any, endDate: any, type: string, task?: any) {
    let color = '';
    let text = '';
    if (!startDate && !endDate) {
      text = '';
    } else if (startDate && !endDate) {
      if (currentDate >= startDate) {
        text = '';
      } else {
        text = `Available on ${moment.unix(startDate).format('DD/MM/YYYY')} `;
      }
    } else if (currentDate < startDate) {
      text = `Available on ${moment.unix(startDate).format('DD/MM/YYYY')} `;
    } else if (currentDate >= startDate && currentDate <= endDate) {
      const timeLeft = endDate - currentDate;
      if (timeLeft <= 7 * 24 * 3600) {
        // 7 days in seconds
        color = 'orange';
      } else {
        color = '';
      }
      text = `Due by ${moment.unix(endDate).format('DD/MM/YYYY')}`;
    } else if (currentDate > endDate) {
      color = 'red';
      text = `Due by ${moment.unix(endDate).format('DD/MM/YYYY')}`;
    } else {
      text = '';
    }

    if (type === 'level') {
      return (
        <Typography fontSize={'12px'} fontStyle={'italic'} color={color}>
          {text}
        </Typography>
      );
    }

    return (
      <Typography
        color={taskId === task._id ? '#FFFFFF' : color}
        fontSize={'12px'}
        lineHeight={'14px'}
        fontStyle={'italic'}
      >
        {text}
      </Typography>
    );
  }

  const getLevelActiveDate = (level: any) => {
    if (level?.startDate?.type === levelTypes.activeNow || level?.startDate === undefined) {
      return true;
    }
    if (level?.startDate?.type === levelTypes.availableSoon) {
      return false;
    }

    if (level?.startDate?.type === levelTypes.calendarDate && currentDate >= level?.startDate?.value) {
      return true;
    }
    if (level?.startDate?.type === levelTypes.daysAfterSignup && currentDate >= level?.startDate?.value) {
      return true;
    }
    return false;
  };

  const getTasks = (obj: any) => {
    return (
      <>
        {obj.map((level: any, index: number) => {
          return (
            <React.Fragment key={level._id}>
              <Box className="main-icon-sec" px={2}>
                <Box>
                  <Typography className="level-name">
                    {getOrfiumLevelName(oid, selectedGroupId?.id, index + 1)}
                  </Typography>
                  <Typography className="your-goal-txt">{level.levelName}</Typography>
                  <Typography fontSize={'12px'} lineHeight={'14px'} fontStyle={'italic'}>
                    {level?.endDate?.value || level?.startDate?.value
                      ? getAvailabilityMessage(level?.startDate?.value, level?.endDate?.value, 'level')
                      : null}
                  </Typography>
                </Box>
                <Box>
                  <img
                    src={getTrophyBadge(level.hexColor, getLevelActiveDate(level))}
                    alt="Trophybadge"
                    width="35px"
                    height="35px"
                  />
                  {!level?.level && groupDetails?.data?.includeTask && groupDetails?.data?.allowEditing && (
                    <MoreVertIcon
                      onClick={(event) => rightTopDotsClick(level)(event)}
                      sx={{ color: '#152536', cursor: 'pointer' }}
                    />
                  )}
                </Box>
              </Box>
              {getLevelActiveDate(level) ? (
                <Box mb={{ xs: 1, sm: 2 }}>
                  {level.tasks.map((task: any, taskIndex: number) => (
                    <Box
                      key={task._id}
                      sx={{
                        cursor: getIsTaskStarted(task?.startDate) ? 'pointer' : 'not-allowed'
                      }}
                    >
                      <Box
                        px={2}
                        gap="15px"
                        height="34px"
                        display="flex"
                        alignItems="center"
                        className="clickable"
                        position={'relative'}
                        bgcolor={taskId === task._id ? '#0071A9' : '#FFFFFF'}
                        onClick={() => {
                          if (getIsTaskStarted(task?.startDate)) {
                            navigateTaskQuestionsToTop('right');
                            navigate(`/app/home/mentee/level/${level._id}/task/${task._id}?userName=${userName}`);
                          }
                        }}
                      >
                        <img
                          width="20px"
                          height="20px"
                          src={
                            task?.taskColor === 'green'
                              ? taskId === task._id
                                ? lightcheckimg
                                : greencheckimg
                              : greycheckimg
                          }
                          alt="Checked icon"
                          className={getIsTaskStarted(task?.startDate) ? 'cursor-pointer' : 'cursor-not-allowed'}
                        />
                        <Box maxWidth="calc(100% - 35px)">
                          <Typography
                            noWrap
                            lineHeight={'normal'}
                            fontWeight={taskId === task._id ? '900' : '400'}
                            ref={taskId === task._id ? selectedTaskRef : null}
                            color={
                              taskId === task._id && task.taskColor !== 'green'
                                ? '#FFFFFF'
                                : task.taskColor === 'green'
                                  ? taskId === task._id
                                    ? '#FFFFFF'
                                    : 'secondary'
                                  : '#0071A9'
                            }
                            className={getIsTaskStarted(task?.startDate) ? 'cursor-pointer' : 'cursor-not-allowed'}
                          >
                            {task.taskName}
                          </Typography>
                          {/* Due date */}
                          <Typography
                            color={
                              taskId === task._id && task.taskColor !== 'green'
                                ? '#FFFFFF'
                                : task.taskColor === 'green'
                                  ? taskId === task._id
                                    ? '#FFFFFF'
                                    : 'secondary'
                                  : 'secondary'
                            }
                            fontSize={'12px'}
                            lineHeight={'14px'}
                            fontStyle={'italic'}
                            className={getIsTaskStarted(task?.startDate) ? 'cursor-pointer' : 'cursor-not-allowed'}
                          >
                            {getAvailabilityMessage(task?.startDate, task?.endDate, 'task', task)}
                          </Typography>
                        </Box>
                      </Box>

                      {/* Y axis line between 2 task */}
                      {taskIndex < level.tasks.length - 1 && (
                        <Box borderLeft="1px dotted #D9D9D9" ml="29px" py="10px" />
                      )}
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box p={2} textAlign={'center'}>
                  {lockIcon({ size: '35px', color: '#68717A' })}
                  <Typography mt={1}>This level will be available soon.</Typography>
                  <Link
                    color={'info.main'}
                    fontStyle={'italic'}
                    onClick={() => {
                      setLevelNumber(index + 1);
                      setLevelDescription(level?.levelDescription || 'This level has no description');
                      handleOpenDescription();
                    }}
                  >
                    Read description of Level {index + 1}
                  </Link>
                </Box>
              )}
              {!level?.level && (level?.startDate?.type === levelTypes.activeNow || level?.startDate === undefined) && (
                <Link onClick={() => handleAddTasks(level)} color="#0071A9" variant="caption" pl={2}>
                  {groupDetails?.data?.includeTask ? (groupDetails?.data?.allowEditing ? '+ Add Lesson' : null) : null}
                </Link>
              )}
              {index < obj.length - 1 && <Divider sx={{ margin: '20px 20px 0' }} />}
            </React.Fragment>
          );
        })}
        {/* edit or delete modal */}
        <Popover
          id={rightTopPopover}
          open={editModalOpen}
          anchorEl={rightTopPop}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Box className="PopoverListStyle" gap="8px" onClick={() => handleEditLevel()}>
            <EditIcon />
            <Typography color="primary">Edit Level</Typography>
          </Box>
          <Box className="PopoverListStyle" gap="8px" onClick={() => handleDeleteLevel()}>
            <DeleteIcon />
            <Typography color="primary">Delete Level</Typography>
          </Box>
        </Popover>
        <MenteeTaskPopup setReloadFlag={setReloadFlag} />
      </>
    );
  };
  const getIsTaskStarted = (startDate: any) => {
    const currentDate = Date.now() / 1000; // Current date in Unix timestamp (seconds)
    if (!startDate || currentDate >= startDate) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (selectedTaskRef.current) {
      selectedTaskRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [taskId, selectedTaskRef]);

  const updateQuery = (levels: any) => {
    const newLevels = levels.map((each: any) => {
      if (each?.startDate?.type === levelTypes.activeNow || each?.startDate === undefined) {
        return { ...each, levelColor: 'green' };
      }
      return each;
    });
    const requiredData = newLevels.map((each: any) => {
      let answeredCount = 0;
      let completedCount = 0;

      each.tasks.forEach((task: any) => {
        if (task?.menteeQuestions?.some((q: any) => q.menteeAns.length > 0) || task.menteeAsmtAns?.length > 0) {
          answeredCount += 1;
        }

        if (task.menteeAsmtAns && task.menteeAsmtAns === 'Yes') {
          completedCount += 1;
        }
      });
      if (completedCount === each.tasks.length) {
        return { ...each, hexColor: 'green' };
      } else if (answeredCount >= 1) {
        return { ...each, hexColor: 'gold' };
      } else {
        return each;
      }
    });
    queryClient.setQueryData([queryKeys.GET_ALL_GROUP_TASKS, selectedGroupId.id, taskUserId], (prev: any) => {
      return { ...prev, data: { ...prev.data, data: [{ ...prev.data.data[0], levels: requiredData }] } };
    });
    dispatch(taskActions.updateTask(requiredData));
  };

  const handleClick = (personType: number, value: any, type: any, quesId: string) => {
    handleInputValueChange.cancel();
    if (saveAnswersLoading) {
      source.cancel('Cancelled...');
      source = axios.CancelToken.source();
    }

    const updatedMenteeQuestions = getDetailTask?.menteeQuestions?.map((item: any) =>
      item._id === quesId ? { ...item, menteeAns: Array.isArray(value) ? value : [value.toString()] } : item
    );

    // Helper function to check if a question has valid answers
    const hasValidAnswer = (item: any) => {
      return (
        item.menteeAns &&
        Array.isArray(item.menteeAns) &&
        item.menteeAns.length > 0 &&
        item.menteeAns.some((ans: any) => ans && ans.trim() !== '')
      );
    };

    if (personType === 0) {
      if (type === 'input' && inputValue?.quesId === quesId) {
        setInputValue({ quesId: quesId, value: value });
      }
      setGetTask((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          menteeQuestions: updatedMenteeQuestions,
          menteeAsmtAns: _.get(getDetailTask, 'menteeAsmtAns', '')
        }
      }));
      const requiredData = allTaskData.map((each: any) => {
        if (each._id === levelId) {
          const requiredTasks = each.tasks.map((task: any) => {
            if (task._id === taskId) {
              return {
                ...task,
                menteeQuestions: updatedMenteeQuestions,
                menteeAsmtAns: _.get(getDetailTask, 'menteeAsmtAns', '')
              };
            }
            return task;
          });
          return { ...each, tasks: requiredTasks };
        }
        return each;
      });
      updateQuery(requiredData);

      // Only include questions with valid answers
      const answeredQuestions = updatedMenteeQuestions.filter(hasValidAnswer).map((item: any) => ({
        menteeAns: item.menteeAns,
        quesId: item._id
      }));

      setTimeout(() => {
        saveAnswers({
          groupId,
          levelId: levelId,
          taskId: taskId,
          menteeAnswers: answeredQuestions,
          menteeAsmtAnswer: _.get(getDetailTask, 'menteeAsmtAns', '')
        });
      }, 1000);
    } else {
      if (type === 'multiselect') {
        setSelectedDropdownValues({ quesId: selectedDropdownValues.quesId, values: selectedDropdownValues?.values });
      } else {
        if (_.get(getDetailTask, 'menteeAnsType') === 'input') {
          setGetTask((prev) => ({
            ...prev,
            data: {
              ...prev.data,
              menteeQuestions: updatedMenteeQuestions,
              menteeAsmtAns: _.get(getDetailTask, 'menteeAsmtAns', ''),
              taskColor: value.toString() === 'Yes' ? 'green' : 'grey'
            }
          }));
          const requiredData = allTaskData.map((each: any) => {
            if (each._id === levelId) {
              const requiredTasks = each.tasks.map((task: any) => {
                if (task._id === taskId) {
                  return {
                    ...task,
                    menteeQuestions: updatedMenteeQuestions,
                    menteeAsmtAns: _.get(getDetailTask, 'menteeAsmtAns', ''),
                    taskColor: value.toString() === 'Yes' ? 'green' : 'grey'
                  };
                }
                return task;
              });
              return { ...each, tasks: requiredTasks };
            }
            return each;
          });
          updateQuery(requiredData);

          // Only include questions with valid answers
          const answeredQuestions = updatedMenteeQuestions.filter(hasValidAnswer).map((item: any) => ({
            menteeAns: item.menteeAns,
            quesId: item._id
          }));

          saveAnswers({
            groupId,
            levelId: levelId,
            taskId: taskId,
            menteeAnswers: answeredQuestions,
            menteeAsmtAnswer: _.get(getDetailTask, 'menteeAsmtAns', '')
          });
        } else {
          setGetTask((prev) => ({
            ...prev,
            data: {
              ...prev.data,
              menteeQuestions: updatedMenteeQuestions,
              menteeAsmtAns: value.toString() || ''
            }
          }));
          const requiredData = allTaskData.map((each: any) => {
            if (each._id === levelId) {
              const requiredTasks = each.tasks.map((task: any) => {
                if (task._id === taskId) {
                  return {
                    ...task,
                    menteeQuestions: updatedMenteeQuestions,
                    menteeAsmtAns: value.toString() || '',
                    taskColor: value.toString() === 'Yes' ? 'green' : 'grey'
                  };
                }
                return task;
              });
              return { ...each, tasks: requiredTasks };
            }
            return each;
          });
          updateQuery(requiredData);

          // Only include questions with valid answers
          const answeredQuestions = updatedMenteeQuestions?.filter(hasValidAnswer).map((item: any) => ({
            menteeAns: item.menteeAns,
            quesId: item._id
          }));

          saveAnswers({
            groupId,
            levelId: levelId,
            taskId: taskId,
            menteeAnswers: answeredQuestions,
            menteeAsmtAnswer: value.toString() || ''
          });
        }
      }
    }
  };

  // const menteeInputAnswers = getDetailTask?.menteeQuestions?.map((item: any) => item?.quesId === item?.menteeAnswers);
  // const [inputValueState] = menteeInputAnswers || ['']; // Provide a default value if menteeInputAnswers is undefined
  const [inputValue, setInputValue] = useState<any>({ quesId: '', value: '' });

  // useEffect(() => {
  //   setInputValue(inputValueState);
  // }, [inputValueState]);

  const handleInputValueChange = _.debounce(() => {
    // Helper function to check if a question has valid answers
    const hasValidAnswer = (item: any) => {
      return (
        item.menteeAns &&
        Array.isArray(item.menteeAns) &&
        item.menteeAns.length > 0 &&
        item.menteeAns.some((ans: any) => ans && ans.trim() !== '')
      );
    };

    setGetTask((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        menteeQuestions: prev.data.menteeQuestions.map((item: any) =>
          item._id === inputValue?.quesId
            ? { ...item, menteeAns: Array.isArray(inputValue?.value) ? inputValue?.value : [inputValue?.value] }
            : item
        )
      }
    }));

    const requiredData = allTaskData.map((each: any) => {
      if (each._id === levelId) {
        const requiredTasks = each.tasks.map((task: any) => {
          if (task._id === taskId) {
            return {
              ...task,
              menteeQuestions: task?.menteeQuestions.map((item: any) =>
                item._id === inputValue?.quesId
                  ? {
                    ...item,
                    menteeAns: Array.isArray(inputValue?.value) ? inputValue?.value : [inputValue?.value]
                  }
                  : item
              )
            };
          }
          return task;
        });
        return { ...each, tasks: requiredTasks };
      }
      return each;
    });
    updateQuery(requiredData);

    // Filter out questions without answers before saving
    const answeredQuestions = getDetailTask?.menteeQuestions?.filter(hasValidAnswer)?.map((item: any) => ({
      menteeAns: item.menteeAns,
      quesId: item._id
    }));

    saveAnswers({
      groupId,
      levelId: levelId,
      taskId: taskId,
      menteeAnswers: answeredQuestions,
      menteeAsmtAnswer: _.get(getDetailTask, 'menteeAsmtAns', '')
    });
  }, 1000);

  const handleAddLevels = () => {
    dispatch(menteeTaskSlicAction.openDialog({ isOpen: true, mode: 'add' }));
  };

  useEffect(() => {
    if (selectedDropdownValues.values.length) {
      const minMenteeAns = 1;
      const timer = setTimeout(() => {
        if (selectedDropdownValues.values.length >= minMenteeAns) {
          setGetTask((prev) => ({
            ...prev,
            data: {
              ...prev.data,
              // menteeAnswers: selectedDropdownValues,
              menteeQuestions: prev.data.menteeQuestions.map((item: any) =>
                item._id === selectedDropdownValues?.quesId
                  ? {
                    ...item,
                    menteeAns: selectedDropdownValues
                  }
                  : item
              ),
              menteeAsmtAns: _.get(getDetailTask, 'menteeAsmtAns', '')
            }
          }));
          const requiredData = allTaskData.map((each: any) => {
            if (each._id === levelId) {
              const requiredTasks = each.tasks.map((task: any) => {
                if (task._id === taskId) {
                  return {
                    ...task,
                    menteeQuestions: task.menteeQuestions.map((item: any) =>
                      item._id === selectedDropdownValues?.quesId
                        ? { ...item, menteeAns: selectedDropdownValues }
                        : item
                    ),
                    menteeAsmtAns: _.get(getDetailTask, 'menteeAsmtAns', ''),
                    taskColor: _.get(getDetailTask, 'menteeAsmtAns', '').toString() === 'Yes' ? 'green' : 'grey'
                  };
                }
                return task;
              });
              return { ...each, tasks: requiredTasks };
            }
            return each;
          });
          updateQuery(requiredData);
          saveAnswers({
            groupId,
            levelId: levelId,
            taskId: taskId,
            menteeAnswers: selectedDropdownValues,
            menteeAsmtAnswer: _.get(getDetailTask, 'menteeAsmtAns', '')
          });
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [selectedDropdownValues]);

  const handleBlurCapture = () => {
    handleInputValueChange();
  };

  const getQuestion = (mergedData: any, idx: number, isLastQuestion: boolean) => {
    const {
      _id,
      menteeAsmtQues,
      menteeAsmtAnsType,
      menteeAsmtAnsOptions,
      menteeAsmtAns,
      menteeQues,
      menteeAnsType,
      menteeAnsOptions,
      menteeAns,
      menteeQuestions
    } = mergedData;
    // If there is no question, return null
    if (!menteeQues && !menteeAsmtQues) return null;
    return (
      <React.Fragment key={idx}>
        <Box display="flex" alignItems="center" pb={2} gap={1}>
          <Typography variant="h5" className={selectedGroupId.role === 'mentor' ? 'opacity-25' : ''}>{menteeAsmtQues || menteeQues}</Typography>
          {selectedGroupId.role === 'mentor' && (
            <Tooltip title="You can not edit this person’s answers">
              {lockIcon({ size: '16px', color: '#68717A' })}
            </Tooltip>
          )}
        </Box>
        <GetTaskQuestionOptions
          disablePointers={taskUserId}
          isMenteeAnsGiven={true} // for making answering questions independent
          personType={idx}
          handleClick={handleClick}
          setInputValue={setInputValue}
          inputValue={inputValue}
          handleBlurCapture={handleBlurCapture}
          selectedDropdownValues={selectedDropdownValues}
          answerGiven={menteeAns || menteeAsmtAns}
          ansOptions={menteeAnsOptions?.length > 0 ? menteeAnsOptions : menteeAsmtAnsOptions}
          type={menteeAnsType || menteeAsmtAnsType}
          answers={mergedData?.menteeAns || []}
          maxMenteeAns={mergedData?.maxMenteeAns || 0}
          minMenteeAns={mergedData?.minMenteeAns || 0}
          handleInputValueChange={handleInputValueChange}
          quesId={_id}
          menteeQuestions={menteeQuestions}
        />
        {!isLastQuestion && <Divider sx={{ margin: '20px 0' }} />}
      </React.Fragment>
    );
  };

  if (!getIsTaskStarted(getDetailTask?.startDate)) {
    return null;
  }
  return (
    <Box className="containerBox">
      <Box className="containerBoxLeft d-desktop" overflow={taskLoading ? 'hidden' : 'auto'}>
        <Box display="flex" alignItems="center" p={2} pb={0}>
          <Box display="flex" ml={-0.5} sx={{ cursor: 'pointer', '& svg': { height: '15px' } }} onClick={goBack}>
            {taskLoading ? (
              <MySkeleton height={20} width={10} sx={{ marginRight: '10px' }} />
            ) : (
              <ArrowBackIosNewOutlinedIcon />
            )}
          </Box>
          <Typography variant="h5" pr={1}>
            {taskLoading ? <MySkeleton height={20} width={100} /> : 'Home'}
          </Typography>
          <>
            {taskLoading ? (
              <MySkeleton height={20} width={70} />
            ) : (
              <Link onClick={handleAddLevels} color="#0071A9" variant="caption">
                {groupDetails?.data?.includeTask ? (groupDetails?.data?.allowEditing ? '+ Add Level' : null) : null}
              </Link>
            )}
          </>
        </Box>

        {taskLoading ? (
          <TasksLoader />
        ) : allTaskData ? (
          <Box>
            {allTaskData.length > 0 ? (
              getTasks(allTaskData)
            ) : (
              <Box p={2}>
                <Typography textAlign="center">No lesson assigned</Typography>
              </Box>
            )}
          </Box>
        ) : taskError ? (
          <Box display="flex" gap="8px" alignItems="center" mt={2}>
            <Error color="error" />
            <Typography color="error">Failed to load levels</Typography>
          </Box>
        ) : (
          <Box p={2}>
            <Typography textAlign="center">No lesson assigned</Typography>
          </Box>
        )}
      </Box>
      <Box className="containerBoxRight">
        {/* For Mobile -> details to back button */}
        {mobileBackBtn('home')}

        {/* Task page user name for Mentor */}
        {selectedGroupId.role === 'mentor' && (
          <Box bgcolor={'#262738'} p={2} borderRadius={'8px'} mb={2}>
            <Typography variant='subtitle2' color={'#FFFFFF'} textAlign={'center'} lineHeight={'normal'}>
              You are now viewing the progress of “{userName}”.
            </Typography>
          </Box>
        )}

        {/* {quesLoading ? (
          <TasksQuesLoader />
        ) : xpPoints ? (
          <Box mb={{ xs: 1, sm: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <ExperiencePoints value={xpPoints} component="MenteeTask" />
              </Grid>
            </Grid>
          </Box>
        ) : null} */}

        {isLoading ? (
          <TasksQuesLoader />
        ) : quesError ? (
          <Box className="first-card-sec" display="flex" alignItems="center" mb={{ xs: 1, sm: 2 }}>
            <Error color="error" />
            <Typography color="red" marginLeft={1}>
              {' '}
              Failed to load lesson{' '}
            </Typography>
          </Box>
        ) : (
          // <TasksQuesLoader />

          <Box className="first-card-sec" mb={{ xs: 1, sm: 2 }}>
            <Typography className="right-sec-heading" pb={2}>
              {getDetailTask?.taskName}
            </Typography>
            <Box pb={2}>
              <RenderHTML
                htmlContent={getDetailTask?.taskDescription}
                fallbackText='No description founda'
              />
            </Box>
            {/* <Typography mb={2}>{getDetailTask?.taskDescription}</Typography> */}

            <Divider />
            {/* Question section  */}
            <Box pt={2}>
              {[
                {
                  question: 'menteeQues',
                  type: 'menteeAnsType',
                  ansOptions: 'menteeAnsOptions',
                  ans: 'menteeAns'
                }
              ]?.map((each, idx) => {
                return getDetailTask?.menteeQuestions?.map((item: any, questionIdx: number) => {
                  // Call the getQuestion function properly by passing the relevant question data
                  const isLastQuestion = questionIdx === getDetailTask.menteeQuestions.length - 1;
                  return getQuestion({ ...item, ...each }, idx, isLastQuestion);
                });
              })}
            </Box>
          </Box>
        )}

        {/* Articles section */}
        <Box mb={2}>
          <RecommendedArticle
            direction="row"
            place="menteeTask"
            isLoading={articlesLoading || articlesFetching}
            data={recArticles?.data?.data || []}
            handleArticleClick={(id, location) => handelOnClickPreviewArticleName(id, location)}
          />
        </Box>

        {/* {articlesLoading || articlesFetching ? (
          <ArticleLoader />
        ) : (
          <Box className="CardBox" mb={{ xs: 1, sm: 2 }}>
            <Box p={2}>
              <Typography variant="h5">Lesson Materials</Typography>
            </Box>
            <Divider />
            <Box className="resume-main-sec">
              {recArticles?.data.data?.length > 0 ? (
                <>
                  {recArticles?.data.data?.map((eachArticle: any) => (
                    <Box
                      className="second-resume-sec clickable"
                      key={eachArticle.id}
                      // onClick={() => navigate(`/app/library?articleId=${eachArticle.id}`)}
                      onClick={() => handelOnClickPreviewArticleName(eachArticle.id, eachArticle.location)}
                    >
                      <img src={eachArticle.coverImageUrl} alt="resumeimg" className="second-card-img" />
                      <Box sx={{ width: '160px' }}>
                        <Tooltip title={eachArticle.articleName}>
                          <Typography className="onlyShowTwoLine" variant="h5">
                            {eachArticle.articleName}
                          </Typography>
                        </Tooltip>
                        <Typography>{eachArticle.readTime}</Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                <Typography>
                  No articles recommended for you yet.
                  <Link href="/#/app/library" color="#0071A9" variant="subtitle2">
                    {' '}
                    Click here{' '}
                  </Link>
                  to see all the articles available.
                </Typography>
              )}
            </Box>
          </Box>
        )} */}

        {/* Have you completed this lesson? */}
        <Box p={2} className={'CardBox'}>
          {[
            {
              question: 'menteeAsmtQues',
              type: 'menteeAsmtAnsType',
              ansOptions: 'menteeAsmtAnsOptions',
              ans: 'menteeAsmtAns'
            }
          ]?.map((each, idx) => {
            return getQuestion({ ...getDetailTask, ...each }, 1, true);
          })}
        </Box>

        {/* Mentor list */}
        {!isOrfium &&
          (mentorsLoading || mentorsFetching ? (
            <MentorLoader />
          ) : (
            <Box className="CardBox" mt={2}>
              <Box display={'flex'} alignItems={'center'} p={2} gap={1}>
                <Typography variant="h5">Lesson Mentors</Typography>
                {selectedGroupId.role === 'mentor' && lockIcon({ size: '16px', color: '#68717A' })}
              </Box>
              <Divider />
              <Box
                display={forMobile ? 'block' : 'flex'}
                width="100%"
                minHeight={!forMobile ? '250px' : 'auto'}
                overflow="auto"
                className={selectedGroupId.role === 'mentor' ? 'opacity-25 disabled-box' : ''}
              >
                <Box width={forMobile ? '100%' : '400px'} minWidth={!forMobile ? '400px' : '100%'}>
                  <Box height="100%" display={'flex'} flexDirection={'column'}>
                    <Box display="flex" pt={2} px={2}>
                      <img src={personmentor} alt="Trophybadge" width="24" height="24" />
                      <Typography className="my-mentor-text">
                        {checkOrfium(oid, selectedGroupId?.id) ? 'My administrator' : 'My ' + mentorRoleTextLowerCase}
                      </Typography>
                    </Box>
                    {/* <MyMentorCard myMentorList={myMentorsList} userProfileRow={handleUserProfile} /> */}
                    <MyMentorCard myMentorList={myMentors} />
                  </Box>
                </Box>

                <Box width={!forMobile ? 'calc(100% - 400px)' : '100%'} minWidth={!forMobile ? '400px' : '100%'}>
                  <Box height="100%" display={'flex'} flexDirection={'column'}>
                    <Box display="flex" pt={2} px={2}>
                      <img src={personmentor} alt="Trophybadge" width="24" height="24" />
                      <Typography className="my-mentor-text">Others with relevant experience</Typography>
                    </Box>
                    {recMentors?.data.data && recMentors?.data.data.length > 0 ? (
                      <MyMentorCard myMentorList={recMentors?.data.data} />
                    ) : (
                      <Box padding={{ xs: 2, sm: 4 }}>
                        <Typography textAlign="center" pt={{ sm: 3 }}>
                          Other people not found.
                          <Link href="/#/app/community" color="#0071A9" variant="subtitle2">Click here</Link>
                          to see all members available.
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))
        }

        {/* Call booking Popup */}
        <CallBookingModal
          handleCloseModal={handleCalendarClose}
          openFlag={openCalendarModal}
          chatProfileName={displayName}
          threadParticipantsUsers={[participantUser]}
          chatType="Chats"
          pageName="Mentor"
          bookedCallDetails={bookedCallDetails}
        />

        <PreviewContent
          openPreview={openPreview}
          setOpenPreview={setOpenPreview}
          singleListData={articleData}
          loading={articleDataLoading}
        />

        {/* Chat with me Popup */}
        {/* <ChatWithMeDialog
          openChatWithMe={openChatWithMe}
          handleClickChatWithMe={handleClickChatWithMe}
        /> */}
      </Box>

      {/* Description Modal */}
      <DescriptionModal
        open={isModalOpen}
        onClose={handleCloseModal}
        title={`Level ${levelNumber} Description`}
        message={levelDescription}
      />
    </Box>
  );
};

export default MenteeTask;
