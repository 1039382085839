import React from "react";
import {
    Grid,
    Dialog,
    Typography,
    DialogContent,
} from "@mui/material";
import LeaderBoardCard from "./LeaderBoardCard";
import CommonModalHeader from "../../../../../components/DialogUi/CommonModalHeader";

interface CompletedLeaderboardUsersProps {
    open: boolean;
    data: any[];
    loader?: boolean;
    onClose: () => void;
}

const CompletedLeaderboardUsers: React.FC<CompletedLeaderboardUsersProps> = ({ open, onClose, data, loader }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            {/* Dialog Header */}
            <CommonModalHeader
                title={"View Completed"}
                handleCloseModal={onClose}
            />
            <DialogContent>
                {data.length > 0 ? (
                    <Grid container spacing={2}>
                        {data.map((item) => (
                            <Grid item xs={12} sm={6} key={item.id}>
                                <LeaderBoardCard
                                    id={item?.id}
                                    mid={item?.mid}
                                    loader={loader}
                                    avgProgress={0}
                                    headshot={item?.headshot}
                                    displayName={item?.displayName}
                                    goalProgress={item?.goalProgress || 0}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Typography textAlign="center" py={2}>
                        We are yet to have our first completed member
                    </Typography>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default CompletedLeaderboardUsers;
